import { useFormik } from "formik";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { EJKNServiceSingleton } from "../../../../services/api/ejkns.service";
import { ITicket, ticketInitialValues as initialValues } from "../TicketModel";

const ticketSchema = Yup.object().shape({
  title: Yup.string().required("Title is required!"),
  zone_id: Yup.string().required("Zone is required!!"),
  address: Yup.string().required("Address is required"),
  description: Yup.string().required("Description is required!"),
  district_id: Yup.string().required("District is required!"),
  latitude: Yup.string().required("Latitude is required!"),
  longitude: Yup.string().required("Longitude is required"),
});

export const TicketForm: React.FC = () => {
  const formik = useFormik<ITicket>({
    initialValues,
    validationSchema: ticketSchema,
    onSubmit: async (values) => {
      const result = await EJKNServiceSingleton.createTicket(values);
      formik.resetForm();
      navigateToTicketDetail(result.data.ticket.id);
    },
  });

  const { zones } = useSelector((state: any) => state.zones);
  const { district } = useSelector((state: any) => state.district);
  const navigate = useNavigate();

  function navigateToTicketDetail(id: number) {
    navigate(`/ticket/detail/${id}`)
  }

  function navigateToTicketList() {
    navigate("/ticket/open");
  }

  return (
    <form onSubmit={formik.handleSubmit} noValidate className="form">
      <div className="card-body border-top p-9">
        <div className="row">
          <div className="col-md-12">
            <label className="col-form-label required fw-bold fs-6">
              Title
            </label>
            <input
              type="text"
              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
              placeholder="Title"
              {...formik.getFieldProps("title")}
            />
            {formik.touched.title && formik.errors.title && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.title}</div>
              </div>
            )}
          </div>
          <div className="col-sm-12 col-md-6">
            <label className="col-form-label required fw-bold fs-6">
              District
            </label>
            <select
              className="form-select form-select-solid form-select-lg"
              {...formik.getFieldProps("district_id")}
            >
              <option value="">Select a district...</option>
              {district.map((d: any) => (
                <option key={d.id} value={d.id}>
                  {d.name}
                </option>
              ))}

              {/* <option value="id">Hewlett Packard</option>
              <option value="msa">Dell</option> */}
            </select>
            {formik.touched.district_id && formik.errors.district_id && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.district_id}</div>
              </div>
            )}
          </div>
          <div className="col-sm-12 col-md-6">
            <label className="col-form-label required fw-bold fs-6">Zone</label>
            <select
              className="form-select form-select-solid form-select-lg"
              {...formik.getFieldProps("zone_id")}
            >
              <option value="">Select a zones...</option>
              {zones.map((z: any) => (
                <option key={z.id} value={z.id}>
                  {z.name}
                </option>
              ))}

              {/* <option value="id">Hewlett Packard</option>
              <option value="msa">Dell</option> */}
            </select>
            {formik.touched.zone_id && formik.errors.zone_id && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.zone_id}</div>
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <label className="col-form-label required fw-bold fs-6">
              Latitude
            </label>
            <input
              type="text"
              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
              placeholder="Latitude"
              {...formik.getFieldProps("latitude")}
            />
            {formik.touched.latitude && formik.errors.latitude && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.latitude}</div>
              </div>
            )}
          </div>
          <div className="col-sm-12 col-md-6">
            <label className="col-form-label required fw-bold fs-6">
              Longitude
            </label>
            <input
              type="text"
              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
              placeholder="Longitude"
              {...formik.getFieldProps("longitude")}
            />
            {formik.touched.longitude && formik.errors.longitude && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.longitude}</div>
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <label className="col-form-label required fw-bold fs-6">
              Description
            </label>
            <textarea
              className="form-control form-control-solid mb-8"
              rows={5}
              placeholder="Description"
              {...formik.getFieldProps("description")}
            ></textarea>
            {formik.touched.description && formik.errors.description && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.description}</div>
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <label className="col-form-label required fw-bold fs-6">
              Address
            </label>
            <textarea
              className="form-control form-control-solid mb-8"
              rows={5}
              placeholder="Enter full address"
              {...formik.getFieldProps("address")}
            ></textarea>
            {formik.touched.address && formik.errors.address && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.address}</div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="card-footer d-flex justify-content-between py-6 px-9">
        <button type="button" className="btn btn-default">
          <span
            className="indicator-progress"
            style={{ display: "block" }}
            onClick={navigateToTicketList}
          >
            Back
            {/* Please wait...{" "} */}
            {/* <span className="spinner-border spinner-border-sm align-middle ms-2"></span> */}
          </span>
        </button>
        <button type="submit" className="btn btn-primary">
          <span className="indicator-progress" style={{ display: "block" }}>
            Submit
            {/* Please wait...{" "} */}
            {/* <span className="spinner-border spinner-border-sm align-middle ms-2"></span> */}
          </span>
        </button>
      </div>
    </form>
  );
};
