import React, { useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { EJKNServiceSingleton } from "../../../services/api/ejkns.service";
import { ToastrServiceSingleton } from "../../../services/toastr/toastr.service";
import { OpenTicketTableLists } from "./components/OpenTicketTableLists";

export const CompletedTicketPage = () => {
  const [loading, setLoading] = useState(false);
  const { myRole } = useSelector(({ roles }: any) => roles);

  const [tickets, setTickets] = useState([]);
  const navigate = useNavigate();

  function navigateToAddNewTicket() {
    navigate("/ticket/open/new");
  }

  function handleDeletionLocally(id: number) {
    setTickets((ticket) => ticket.filter((t: any) => t.id !== id));
  }

  useEffect(() => {
    const getTicketsLists = async () => {
      try {
        setLoading(true);
        const lists = await EJKNServiceSingleton.completedTicketsLists();
        if (lists.data.length > 0) setTickets(lists.data);
      } catch (error) {
        ToastrServiceSingleton.error("Fail to fetch completed ticket lists");
      } finally {
        setLoading(false);
      }
    };
    getTicketsLists();
  }, []);
  return (
    <div className="row g-5 g-xl-8">
      <div className="col-sm-12">
        <div className="card card-xl-stretch mb-xl-8">
          {/* begin::Header */}
          <div className="card-header border-0 pt-5">
            {/* begin::Title */}
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bold text-dark">
                Completed Tickets
              </span>
            </h3>
          </div>
          {/* end::Header */}

          {/* begin::Body */}
          <div className="card-body">
            {loading ? (
              <>
                <SkeletonTheme>
                  {/* <div className="row col-md-12"> */}
                  <Skeleton width={"100%"} height={30} />
                  <Skeleton count={5} width={"100%"} height={30} />
                  {/* </div> */}
                </SkeletonTheme>
              </>
            ) : (
              <OpenTicketTableLists
                tickets={tickets}
                handleDeletionLocally={handleDeletionLocally}
                administrator={myRole === "Administrator" || myRole === "Pilot"}
              />
            )}
          </div>
          {/* end::Body */}
        </div>
      </div>
    </div>
  );
};
