import { createSlice } from "@reduxjs/toolkit";

interface IDistrict {
  id: number;
  name: string;
  description: string;
  state_id: number;
  created_at: string;
  updated_at: string;
}

interface IDistrictState {
  district: IDistrict[];
  isLoading: boolean;
  error: string | null;
  success: boolean | null;
}

const initialState: IDistrictState = {
  district: [],
  isLoading: false,
  error: null,
  success: false

};

const districtSlice = createSlice({
  name: "district",
  initialState,
  reducers: {
    districtSuccess: (state, action) => {
      state.isLoading = false;
      state.district = action.payload;
      state.error = null;
      state.success = true;
    },
    districtErrors: (state, action) => {
      state.isLoading = false;
      state.district = [];
      state.error = action.payload;
      state.success = false;
    },
    districtLoading: (state, action) => {
      state.isLoading = action.payload;
    }
  },
  extraReducers: {},
});

export const {districtSuccess, districtErrors, districtLoading} = districtSlice.actions;

export default districtSlice.reducer;
