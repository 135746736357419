export interface IZone {
  name: string;
  description: string;
  state_id: string;
  id?: number;
}

export const zoneInitialValues: IZone = {
  name: "",
  description: "",
  state_id: "",
};
