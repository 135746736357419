import React from "react";
import PermitTypeForm from "./components/PermitTypeForm";
import { withPermitTypeFormLogic } from "./hoc/PermitTypeFormLogic";

const PermitTypeFormWithLogic = withPermitTypeFormLogic(PermitTypeForm);
const AddPermitTypePage: React.FC = () => {
  return (
    <>
      <PermitTypeFormWithLogic />
    </>
  );
};

export default AddPermitTypePage;
