/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useRef, ReactNode } from "react";
import { createPortal } from "react-dom";
import { Modal } from "react-bootstrap";

type Props = {
  show: boolean;
  handleClose: () => void;
  children: ReactNode
};

const modalsRoot = document.getElementById("root-modals") || document.body;

const ConfirmationModal = ({ show, handleClose, children}: Props) => {
  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-900px"
      show={show}
      onHide={handleClose}
      backdrop={true}
    >
      {children}
    </Modal>,
    modalsRoot
  );
};

export { ConfirmationModal };
