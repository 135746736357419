import { toast } from 'react-toastify';


class ToastrService {
    private static instance: ToastrService;
  private _toastr: any;

  private constructor() {}

  public static getInstance(): ToastrService {
    if (!ToastrService.instance) {
        ToastrService.instance = new ToastrService();
        ToastrService.instance._settingToastr();
    }

    return ToastrService.instance;
  }

  private _settingToastr() {
    this._toastr = toast;
  }

  success(message: string) {
    this._toastr.success(message)
  }

  error(message: string) {
    this._toastr.error(message);
  }

}

export const ToastrServiceSingleton = ToastrService.getInstance();