import React from "react";
import StateForm from "./components/StateForm";
import { withStateFormLogic } from "./hoc/StateFormLogic";

const StateFormWithLogic = withStateFormLogic(StateForm);
const AddStatePage: React.FC = () => {
  return (
    <>
      <StateFormWithLogic />
    </>
  );
};

export default AddStatePage;
