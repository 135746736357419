import React from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import AddRolePage from "./AddRolePage";
import RoleLists from "./RoleLists";

const roleBreadCrumb: Array<PageLink> = [
  {
    title: "Add Role",
    path: "/role/add",
    isSeparator: false,
    isActive: false,
  },
  {
    title: "List of Roles",
    path: "/role/lists",
    isSeparator: true,
    isActive: false,
  },
];

const RolePage: React.FC = () => {
  return (
    <Routes>
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      >
        <Route
          path="edit/:id"
          element={
            <>
              <PageTitle breadcrumbs={roleBreadCrumb}>Edit Role</PageTitle>
              <AddRolePage />
            </>
          }
        />
        <Route
          path="add"
          element={
            <>
              <PageTitle breadcrumbs={roleBreadCrumb}>Add Role</PageTitle>
              <AddRolePage />
            </>
          }
        />
        <Route
          path="lists"
          element={
            <>
              <PageTitle breadcrumbs={roleBreadCrumb}>List of Roles</PageTitle>
              <RoleLists />
            </>
          }
        />
      </Route>
    </Routes>
  );
};

export default RolePage;
