import { createSlice } from "@reduxjs/toolkit";

interface IZones {
  id: number;
  name: string;
  description: string;
  state_id: number;
  created_at: string;
  updated_at: string;
}

interface IZonesState {
  zones: IZones[];
  isLoading: boolean;
  error: string | null;
  success: boolean | null;
}

const initialState: IZonesState = {
  zones: [],
  isLoading: false,
  error: null,
  success: false

};

const zonesSlice = createSlice({
  name: "zones",
  initialState,
  reducers: {
    zonesSuccess: (state, action) => {
      state.isLoading = false;
      state.zones = action.payload;
      state.error = null;
      state.success = true;
    },
    zonesErrors: (state, action) => {
      state.isLoading = false;
      state.zones = [];
      state.error = action.payload;
      state.success = false;
    },
    zonesLoading: (state, action) => {
      state.isLoading = action.payload;
    }
  },
  extraReducers: {},
});

export const {zonesSuccess, zonesErrors, zonesLoading} = zonesSlice.actions;

export default zonesSlice.reducer;
