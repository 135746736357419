import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { EJKNServiceSingleton } from "../../../../services/api/ejkns.service";
import { ToastrServiceSingleton } from "../../../../services/toastr/toastr.service";
const listOfStatuses = [
  { name: "Open", value: 1 },
  { name: "In Progress", value: 3 },
  { name: "Verified", value: 4 },
  { name: "Partially Completed", value: 5 },
  { name: "Completed", value: 6 },
];

/* eslint-disable jsx-a11y/anchor-is-valid */
export function StatusDropDown({
  onSignature = () => {},
  status_id = 1,
  updateTicketStatus = (status_id: number) => {},
  statuses = [],
}) {
  const { id } = useParams();
  const [status, setStatus] = useState("1");
  const { user } = useSelector(({ global }: any) => global);
  const [loading, setLoading] = useState(false);

  async function handleChange(event: any) {
    try {
      setLoading(true);
      setStatus(event.target.value);
      await EJKNServiceSingleton.createLog({
        ticket_id: id,
        user_id: user.id,
        status_id: Number(event.target.value),
      });
      updateTicketStatus(Number(event.target.value));
      ToastrServiceSingleton.success("Success update ticket status");
    } catch (error) {
      ToastrServiceSingleton.success("Fail to update ticket status!");
    } finally {
      setLoading(false);
    }
  }

  return (
    <div
      className="menu menu-sub menu-sub-dropdown w-250px w-md-300px"
      data-kt-menu="true"
    >
      {/* <form> */}
      <div className="px-7 py-5">
        <div className="fs-5 text-dark fw-bolder">Action</div>
      </div>

      <div className="separator border-gray-200"></div>

      <div className="px-7 py-5">
        <div className="mb-2">
          <label className="form-label fw-bold">
            Status:
            {loading && (
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            )}
          </label>

          <div>
            <select
              className="form-select form-select-solid"
              data-kt-select2="true"
              data-placeholder="Select option"
              data-allow-clear="true"
              defaultValue={status_id}
              onChange={handleChange}
              name={status.toString()}
            >
              {statuses.map((status) => (
                <option key={status.value} value={status.value}>
                  {status.name}
                </option>
              ))}

              {/* <option value="3">In Progress</option>
                <option value="4">Verification</option>
                <option value="6">Completed</option> */}
            </select>
          </div>
        </div>
      </div>

      {/* <div className="px-7 py-5">
          <div className="mb-10">
            <label className="form-label fw-bold">Sign</label>

            <div style={{ width: "100%" }}>
              <button
                type="button"
                className="btn btn-primary btn-block"
                onClick={onSignature}
              >
                Signature
              </button>
            </div>
          </div>
        </div> */}
      {/* </form> */}
    </div>
  );
}
