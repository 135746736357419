export interface IOrganization {
    name: string
    description: string
    id?:number
  
  }
  
  export const defaultAccount: IOrganization = {
    name: 'max_stone',
    description: 'nick.watson@loop.com',
  
  }
  

  export const organizationInitialValues: IOrganization = {
    name: "",
    description: ""
  }