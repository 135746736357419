import { createSlice } from "@reduxjs/toolkit";

interface IRole {
  name: string;
  description: string;
  id?: number;
}

interface IRoleState {
  role: IRole[];
  isLoading: boolean;
  error: string | null;
  success: boolean | null;
  myRole: string | null;
}

const initialState: IRoleState = {
  role: [],
  isLoading: false,
  error: null,
  success: false,
  myRole: null,
};

const roleSlice = createSlice({
  name: "role",
  initialState,
  reducers: {
    replaceRole: (state, action) => {
      state.isLoading = false;
      state.role = action.payload;
      state.error = null;
      state.success = true;
    },
    addRole: (state, action) => {
      state.isLoading = false;
      state.role = [...state.role, action.payload];
      state.error = null;
      state.success = true;
    },
    removeRole: (state, action) => {
      state.isLoading = false;
      state.role = state.role.filter((org: any) => org.id !== action.payload);
      state.error = null;
      state.success = true;
    },
    loading: (state, action) => {
      state.isLoading = action.payload;
    },
    setMyRole: (state, action) => {
      state.myRole = action.payload;
    },
  },
  extraReducers: {},
});

export const { loading, replaceRole, removeRole, setMyRole } =
  roleSlice.actions;

export default roleSlice.reducer;
