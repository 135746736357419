import React from "react";
import { Route, Routes } from "react-router-dom";
import MapElement from "./MapElement";

export const MapPage = () => {
  return (
    <Routes>
      <Route path="area" element={<MapElement />} />
    </Routes>
  );
};
