import Collection from "@arcgis/core/core/Collection";
import Point from "@arcgis/core/geometry/Point";
import Graphic from "@arcgis/core/Graphic";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import Field from "@arcgis/core/layers/support/Field";
import Map from "@arcgis/core/Map";
import SimpleMarkerSymbol from "@arcgis/core/symbols/SimpleMarkerSymbol";
import MapView from "@arcgis/core/views/MapView";
import Legend from "@arcgis/core/widgets/Legend";
import Search from "@arcgis/core/widgets/Search";
import CustomContent from "@arcgis/core/popup/content/CustomContent";
import PopupTemplate from "@arcgis/core/PopupTemplate";
import UniqueValueRenderer from "@arcgis/core/renderers/UniqueValueRenderer";
import PictureMarkerSymbol from "@arcgis/core/symbols/PictureMarkerSymbol";
import MarkerSvg from "../../_metronic/assets/marker.svg";
import SimpleRenderer from "@arcgis/core/renderers/SimpleRenderer";
import ScaleBar from "@arcgis/core/widgets/ScaleBar";

import BasemapToggle from "@arcgis/core/widgets/BasemapToggle";
import TextSymbol from "@arcgis/core/symbols/TextSymbol";
import Extent from "@arcgis/core/geometry/Extent";

export class ArcGIS {
  private static _instance: ArcGIS;
  private _map: Map;
  private _view: MapView;
  private _Layer: FeatureLayer;
  private _SearchWidget: Search;
  private _LegendWidget: Legend;
  public ticketLayer: FeatureLayer;
  public dropLayer: FeatureLayer;

  private constructor() {
    this._map = new Map({
      basemap: "streets-navigation-vector",
    });

    this._view = new MapView({
      map: this._map,
      center: [101.642, 2.9279],
      zoom: 14,
    });

    this._SearchWidget = new Search({
      view: this._view,
    });

    this._view.ui.add(this._SearchWidget, "top-right");

    this._LegendWidget = new Legend({
      view: this._view,
      visible: false,
    });

    this._view.ui.add(this._LegendWidget, "top-right");

    this._view.ui.add(
      new ScaleBar({
        view: this._view,
        unit: "metric",
      }),
      "bottom-left"
    );

    let vasemapToggle = new BasemapToggle({
      view: this._view,
      nextBasemap: "hybrid",
    });
    // Add widget to the top right corner of the view
    this._view.ui.add(vasemapToggle, {
      position: "bottom-right",
    });
  }

  public static getInstance() {
    if (!ArcGIS._instance) {
      ArcGIS._instance = new ArcGIS();
    }

    return ArcGIS._instance;
  }

  public get map() {
    return this._map;
  }

  public get view() {
    return this._view;
  }

  public set map(map: Map) {
    this._map = map;
  }

  public set view(view: MapView) {
    this._view = view;
  }

  public get Layer() {
    return this._Layer;
  }

  public set Layer(layer: FeatureLayer) {
    this._Layer = layer;
  }

  public get LegendWidget() {
    return this._LegendWidget;
  }

  public async initializeTicketLayer(tickets) {
    if (tickets && tickets.length > 0) {
      let openPoint = new SimpleMarkerSymbol({
        color: "#009ef7",
        size: "15px",
        outline: {
          color: "white",
          width: 1.5,
        },
      });

      let inprogressPoint = new SimpleMarkerSymbol({
        color: "#ffc700",
        size: "15px",
        outline: {
          color: "white",
          width: 1.5,
        },
      });

      let jupemProgressPoint = new SimpleMarkerSymbol({
        color: "#72F7E9",
        size: "15px",
        outline: {
          color: "white",
          width: 1.5,
        },
      });

      let caamprogressPoint = new SimpleMarkerSymbol({
        color: "#DCE1FF",
        size: "15px",
        outline: {
          color: "white",
          width: 1.5,
        },
      });

      let verified = new SimpleMarkerSymbol({
        color: "#8950FC",
        size: "15px",
        outline: {
          color: "white",
          width: 1.5,
        },
      });

      let partially = new SimpleMarkerSymbol({
        color: "#F64E60",
        size: "15px",
        outline: {
          color: "white",
          width: 1.5,
        },
      });

      let closedPoint = new SimpleMarkerSymbol({
        color: "#5BC980",
        size: "15px",
        outline: {
          color: "white",
          width: 1.5,
        },
      });

      let sourceFL: Collection<Graphic> = new Collection<Graphic>();

      tickets.forEach((ticket, index) => {
        sourceFL.add(
          new Graphic({
            geometry: new Point({
              latitude: ticket.latitude,
              longitude: ticket.longitude,
            }),
            symbol: openPoint,
            attributes: {
              OBJECTID: ticket.id,
              title: ticket.title,
              number: ticket.number,
              description: ticket.description,
              status: ticket.status_name,
              address: ticket.address,
              totalDropPoint: ticket.droppoint_count,
            },
          })
        );
      });

      let tempLayer = this.map.allLayers.find((x) => x.title === "Ticket");

      if (tempLayer) {
        this.map.remove(tempLayer);
      }

      this._Layer = new FeatureLayer({
        title: "Ticket",
        source: sourceFL,
        objectIdField: "OBJECTID",
        outFields: ["*"],
        popupEnabled: true,
        popupTemplate: this.CustomPopupTemplate,
        fields: [
          new Field({
            name: "OBJECTID",
            alias: "OBJECTID",
            type: "oid",
          }),
          new Field({
            name: "title",
            alias: "title",
            type: "string",
          }),
          new Field({
            name: "number",
            alias: "number",
            type: "string",
          }),
          new Field({
            name: "status",
            alias: "status",
            type: "string",
          }),
          new Field({
            name: "totalDropPoint",
            alias: "totalDropPoint",
            type: "small-integer",
          }),
        ],
        renderer: new UniqueValueRenderer({
          field: "status",
          uniqueValueInfos: [
            {
              value: "Open",
              symbol: openPoint,
              label: "Open",
            },
            {
              value: "In Progress",
              symbol: inprogressPoint,
              label: "In Progress",
            },
            {
              value: "JUPEM Permit",
              symbol: jupemProgressPoint,
              label: "JUPEM Permit",
            },
            {
              value: "CAAM Permit",
              symbol: caamprogressPoint,
              label: "CAAM Permit",
            },
            {
              value: "Verified",
              symbol: verified,
              label: "Verified",
            },
            {
              value: "Partially Completed",
              symbol: partially,
              label: "Partially Completed",
            },
            {
              value: "Completed",
              symbol: closedPoint,
              label: "Completed",
            },
          ],
        }),
        // renderer: new SimpleRenderer({
        //   symbol: openPoint,
        //   visualVariables: [
        //     new ColorVariable({

        //     }),
        //   ],
        // }),
      });

      this._map.add(this._Layer);
      this._view.goTo(sourceFL);
    }
  }

  public async initializeTicketLayerV2(tickets) {
    return new Promise((resolve, reject) => {
      if (tickets && tickets.length > 0) {
        let openPoint = new SimpleMarkerSymbol({
          color: "#009ef7",
          size: "15px",
          outline: {
            color: "white",
            width: 1.5,
          },
        });

        let inprogressPoint = new SimpleMarkerSymbol({
          color: "#ffc700",
          size: "15px",
          outline: {
            color: "white",
            width: 1.5,
          },
        });

        let jupemProgressPoint = new SimpleMarkerSymbol({
          color: "#72F7E9",
          size: "15px",
          outline: {
            color: "white",
            width: 1.5,
          },
        });

        let caamprogressPoint = new SimpleMarkerSymbol({
          color: "#DCE1FF",
          size: "15px",
          outline: {
            color: "white",
            width: 1.5,
          },
        });

        let verified = new SimpleMarkerSymbol({
          color: "#8950FC",
          size: "15px",
          outline: {
            color: "white",
            width: 1.5,
          },
        });

        let partially = new SimpleMarkerSymbol({
          color: "#F64E60",
          size: "15px",
          outline: {
            color: "white",
            width: 1.5,
          },
        });

        let closedPoint = new SimpleMarkerSymbol({
          color: "#5BC980",
          size: "15px",
          outline: {
            color: "white",
            width: 1.5,
          },
        });

        let sourceFL: Collection<Graphic> = new Collection<Graphic>();

        tickets.forEach((ticket, index) => {
          sourceFL.add(
            new Graphic({
              geometry: new Point({
                latitude: ticket.latitude,
                longitude: ticket.longitude,
              }),
              symbol: openPoint,
              attributes: {
                OBJECTID: ticket.id,
                title: ticket.title,
                number: ticket.number,
                description: ticket.description,
                status: ticket.status_name,
                address: ticket.address,
                totalDropPoint: ticket.droppoint_count,
              },
            })
          );
        });

        let tempLayer = this.map.allLayers.find((x) => x.title === "Ticket");

        if (tempLayer) {
          this.map.remove(tempLayer);
        }

        this.ticketLayer = new FeatureLayer({
          title: "Ticket",
          source: sourceFL,
          objectIdField: "OBJECTID",
          outFields: ["*"],
          popupEnabled: true,
          popupTemplate: this.CustomPopupTemplate,
          fields: [
            new Field({
              name: "OBJECTID",
              alias: "OBJECTID",
              type: "oid",
            }),
            new Field({
              name: "title",
              alias: "title",
              type: "string",
            }),
            new Field({
              name: "number",
              alias: "number",
              type: "string",
            }),
            new Field({
              name: "status",
              alias: "status",
              type: "string",
            }),
            new Field({
              name: "totalDropPoint",
              alias: "totalDropPoint",
              type: "small-integer",
            }),
          ],
          renderer: new UniqueValueRenderer({
            field: "status",
            uniqueValueInfos: [
              {
                value: "Open",
                symbol: openPoint,
                label: "Open",
              },
              {
                value: "In Progress",
                symbol: inprogressPoint,
                label: "In Progress",
              },
              {
                value: "JUPEM Permit",
                symbol: jupemProgressPoint,
                label: "JUPEM Permit",
              },
              {
                value: "CAAM Permit",
                symbol: caamprogressPoint,
                label: "CAAM Permit",
              },
              {
                value: "Verified",
                symbol: verified,
                label: "Verified",
              },
              {
                value: "Partially Completed",
                symbol: partially,
                label: "Partially Completed",
              },
              {
                value: "Completed",
                symbol: closedPoint,
                label: "Completed",
              },
            ],
          }),
        });

        this._map.add(this.ticketLayer);
        this._view.goTo(sourceFL);
        resolve(true);
      } else {
        resolve(true);
      }
    });
  }

  public async initializeDropPointLayer(droppoints) {
    if (droppoints && droppoints.length > 0) {
      let dropPointMarker = new PictureMarkerSymbol({
        url: MarkerSvg,
        width: "30px",
        height: "30px",
      });

      let simplePointMarker = new SimpleMarkerSymbol({
        color: "#de2900",
        size: "10px",
        outline: {
          color: "white",
          width: "2px",
        },
      });

      let textPointMarke = new TextSymbol({
        color: "#F58223",
        text: "\ue61d",
        font: {
          size: 20,
          family: "CalciteWebCoreIcons",
        },
      });

      let sourceFL: Collection<Graphic> = new Collection<Graphic>();

      droppoints.forEach((droppoint, index) => {
        let imageurl = `${process.env.REACT_APP_STORAGE_URL}/${droppoint.file_name}.${droppoint.file_type}`;
        sourceFL.add(
          new Graphic({
            geometry: new Point({
              latitude: droppoint.latitude,
              longitude: droppoint.longitude,
              spatialReference: {
                wkid: 4326,
              },
            }),
            symbol: simplePointMarker,
            attributes: {
              OBJECTID: droppoint.id,
              address: droppoint.title,
              description: droppoint.description,
              imageurl: imageurl,
            },
          })
        );
      });

      let tempLayer = this.map.allLayers.find((x) => x.title === "Drop Point");

      if (tempLayer) {
        this.map.remove(tempLayer);
      }

      console.log("sourceFL", sourceFL);

      this._Layer = new FeatureLayer({
        title: "Drop Point",
        source: sourceFL,
        objectIdField: "OBJECTID",
        outFields: ["*"],
        popupEnabled: true,
        popupTemplate: this.CustomDropPointPopupTemplate,
        maxScale: 0,
        minScale: 5000,
        fields: [
          new Field({
            name: "OBJECTID",
            alias: "OBJECTID",
            type: "oid",
          }),
          new Field({
            name: "address",
            alias: "address",
            type: "string",
          }),
          new Field({
            name: "description",
            alias: "description",
            type: "string",
          }),
          new Field({
            name: "imageurl",
            alias: "imageurl",
            type: "string",
          }),
        ],
        renderer: new SimpleRenderer({
          symbol: simplePointMarker,
        }),
      });

      console.log("Drop PointLayer", this._Layer);

      this._map.add(this._Layer);
    }
  }

  public async initializeDropPointLayerV2(droppoints) {
    let fullExtent = null;
    return new Promise((resolve, reject) => {
      if (droppoints && droppoints.length > 0) {
        let dropPointMarker = new PictureMarkerSymbol({
          url: MarkerSvg,
          width: "30px",
          height: "30px",
        });

        let simplePointMarker = new SimpleMarkerSymbol({
          color: "#de2900",
          size: "10px",
          outline: {
            color: "white",
            width: "2px",
          },
        });

        let textPointMarke = new TextSymbol({
          color: "#F58223",
          text: "\ue61d",
          font: {
            size: 20,
            family: "CalciteWebCoreIcons",
          },
        });

        let sourceFL: Collection<Graphic> = new Collection<Graphic>();

        droppoints.forEach((droppoint, index) => {
          // let imageurl = `${process.env.REACT_APP_STORAGE_URL}/${droppoint.file_name}.${droppoint.file_type}`;
          let tempPoint = new Point({
            latitude: droppoint.latitude,
            longitude: droppoint.longitude,
            spatialReference: {
              wkid: 4326,
            },
          });
          let geometryExtent = new Extent({
            xmin: tempPoint.x - 0.00001,
            xmax: tempPoint.x + 0.00001,
            ymin: tempPoint.y - 0.00001,
            ymax: tempPoint.y + 0.00001,
            spatialReference: tempPoint.spatialReference,
          });
          fullExtent = fullExtent
            ? fullExtent.union(geometryExtent)
            : geometryExtent;
          sourceFL.add(
            new Graphic({
              geometry: tempPoint,
              symbol: simplePointMarker,
              attributes: {
                //   OBJECTID: droppoint.id,
                //   address: droppoint.title,
                //   description: droppoint.description,
                //   imageurl: imageurl,
              },
            })
          );
        });

        let tempLayer = this.map.allLayers.find(
          (x) => x.title === "Drop Point"
        );

        if (tempLayer) {
          this.map.remove(tempLayer);
        }

        console.log("sourceFL", sourceFL);

        this.dropLayer = new FeatureLayer({
          title: "Drop Point",
          source: sourceFL,
          objectIdField: "OBJECTID",
          outFields: ["*"],
          popupEnabled: true,
          popupTemplate: this.CustomDropPointPopupTemplate,
          // maxScale: 0,
          // minScale: 5000,
          fields: [
            new Field({
              name: "OBJECTID",
              alias: "OBJECTID",
              type: "oid",
            }),
            new Field({
              name: "address",
              alias: "address",
              type: "string",
            }),
            new Field({
              name: "description",
              alias: "description",
              type: "string",
            }),
            new Field({
              name: "imageurl",
              alias: "imageurl",
              type: "string",
            }),
          ],
          renderer: new SimpleRenderer({
            symbol: simplePointMarker,
          }),
        });

        console.log("Drop PointLayer", this.dropLayer);

        this._map.add(this.dropLayer);
        this._view.goTo(fullExtent);
        resolve(true);
      } else resolve(true);
    });
  }

  public get CustomPopupTemplate() {
    let _contentTemplate = new CustomContent({
      outFields: ["*"],
      creator: (event: any) => {
        const { graphic } = event;
        const status =
          graphic.attributes.status.toLowerCase() === "open"
            ? '<span class="badge rounded-pill text-bg-primary">Open</span>'
            : graphic.attributes.status.toLowerCase() === "pending"
            ? '<span class="badge rounded-pill text-bg-warning">Pending</span>'
            : graphic.attributes.status.toLowerCase() === "in progress"
            ? '<span class="badge rounded-pill text-bg-info">In Progress</span>'
            : graphic.attributes.status.toLowerCase() === "closed"
            ? '<span class="badge rounded-pill text-bg-success">Closed</span>'
            : "";
        return `
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">${graphic.attributes.title}</h5>
              <h6 class="card-subtitle mb-2 text-muted">${graphic.attributes.number}</h6>
            </div>
            <div class="card-body">
              <p class="card-text">${status}</p>
              <p class="card-text">Total drop point : ${graphic.attributes.totalDropPoint}</p>
            </div>
          </div>
        `;
      },
    });

    return new PopupTemplate({
      outFields: ["OBJECTID, title, number, status, totalDropPoint"],
      content: [_contentTemplate],
      title: "Ticket",
      actions: new Collection([
        {
          id: "view-ticket",
          title: "View Ticket",
          className: "esri-icon-documentation",
        },
        {
          id: "view-drop-point",
          title: "View Drop Points",
          className: "esri-icon-map-pin",
        },
      ]),
    });
  }

  public get CustomDropPointPopupTemplate() {
    let _contentTemplate = new CustomContent({
      outFields: ["*"],
      creator: (event: any) => {
        const { graphic } = event;
        return `
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">${graphic.attributes.description}</h5>
            </div>
            <div class="card-body">
              <p class="card-text"><a href="${graphic.attributes.imageurl}"><img src='${graphic.attributes.imageurl}' width="300px" height="300px" /></a></p>
            </div>
          </div>

        `;
      },
    });

    return new PopupTemplate({
      outFields: ["OBJECTID, address, description"],
      content: [_contentTemplate],
      title: "Drop Point",
    });
  }
}
