import { createSlice } from "@reduxjs/toolkit";

interface IUser {
  name: string;
  email: string;
  phone_number: string;
  role_id: string;
  organization_id: string;
  access_token: string;
}

interface IToken {
  status: boolean;
  message: string;
  access_token: string;
  token_type: string;
  expires_at: string;
}

interface IGlobalState {
  user: IUser | null;
  isLoading: boolean;
  error: string | null;
  success: boolean | null;
  token: IToken | null;
  configured: boolean;
}

const initialState: IGlobalState = {
  user: null,
  isLoading: false,
  error: null,
  success: false,
  token: null,
  configured: false,
};

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    login: (state) => {
      state.isLoading = true;
    },
    success: (state, action) => {
      state.isLoading = false;
      state.token = action.payload;
      state.error = null;
      state.success = true;
    },
    userSuccess: (state, action) => {
      state.user = action.payload;
      state.isLoading = false;
      state.error = null;
      state.success = true;
    },
    error: (state, action) => {
      state.isLoading = false;
      state.user = null;
      state.error = action.payload;
      state.success = false;
    },
    logout: (state) => {
      state.isLoading = false;
      state.user = null;
    },
    loading: (state, action) => {
      state.isLoading = action.payload;
    },
    setConfigured: (state, action) => {
      state.configured = action.payload;
    },
    reset: (state) => {
      state.isLoading = false;
      state.token = null;
      state.error = null;
      state.success = false;
      state.user = null;
      state.configured = false;
    },
  },
  extraReducers: {},
});

export const {
  login,
  success,
  error,
  loading,
  logout,
  userSuccess,
  setConfigured,
  reset,
} = globalSlice.actions;

export default globalSlice.reducer;
