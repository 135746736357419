import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { EJKNServiceSingleton } from "../../../../services/api/ejkns.service";
import { ToastrServiceSingleton } from "../../../../services/toastr/toastr.service";
import { IState, stateInitialValues } from "../StateModel";

export const withStateFormLogic = (WrappedComponent: any) => {
  return () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [initialValues, setInitialValues] =
      useState<IState>(stateInitialValues);

    useEffect(() => {
      if (id) {
        const getStateDetails = async (id: number) => {
          try {
            setLoading(true);
            const data = await EJKNServiceSingleton.getStateById(id);
            setInitialValues(data.data);
          } catch (error) {
            ToastrServiceSingleton.error("Failed to fetch state details");
          } finally {
            setLoading(false);
          }
        };
        getStateDetails(Number(id));
      } else {
        setInitialValues(stateInitialValues);
      }
    }, [id]);

    return (
      <WrappedComponent
        initialValues={initialValues}
        mainLoading={loading}
        onFormSubmit={
          id
            ? (id: number, data: any) =>
                EJKNServiceSingleton.updateState(id, data)
            : (data: any) => EJKNServiceSingleton.createState(data)
        }
      />
    );
  };
};
