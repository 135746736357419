import React from "react";
import OrganizationForm from "./components/RoleForm";
import { withRoleFormLogic } from "./hoc/RoleFormLogic";

const RoleFormWithLogic = withRoleFormLogic(OrganizationForm);
const AddRolePage: React.FC = () => {
  return (
    <>
      <RoleFormWithLogic />
    </>
  );
};

export default AddRolePage;
