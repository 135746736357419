import React from 'react'
import { TicketForm } from './components/TicketForm'

export const AddNewTicketPage = () => {
  return (
    <div className="card mb-5 mb-xl-10">
    <div
      className="card-header border-0 cursor-pointer"
      role="button"
      data-bs-toggle="collapse"
      data-bs-target="#kt_account_profile_details"
      aria-expanded="true"
      aria-controls="kt_account_profile_details"
    >
      <div className="card-title m-0">
        <h3 className="fw-bolder m-0">Add New Ticket</h3>
      </div>
    </div>
    <TicketForm />
  </div>
  )
}
