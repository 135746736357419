import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { EJKNServiceSingleton } from "../../../../services/api/ejkns.service";
import { ToastrServiceSingleton } from "../../../../services/toastr/toastr.service";
import { IOrganization, organizationInitialValues } from "../OrganizationModel";

export const withOrganizationFormLogic = (WrappedComponent: any) => {
  return () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [initialValues, setInitialValues] = useState<IOrganization>(
      organizationInitialValues
    );

    useEffect(() => {
      if (id) {
        const getOrgDetails = async (id: number) => {
            try {
                setLoading(true);
                const data = await EJKNServiceSingleton.getOrganizationById(id);
                setInitialValues(data.data);
            } catch (error) {
                ToastrServiceSingleton.error("Failed to fetch organization details");
            }
            finally {
                setLoading(false);
            }
           
        };
        getOrgDetails(Number(id));
      }
      else {
        setInitialValues(organizationInitialValues);
      }
    }, [id]);

    return (
      <WrappedComponent
        initialValues={initialValues}
        mainLoading={loading}
        onFormSubmit={
          id
            ? (id: number, data: any) =>
                EJKNServiceSingleton.updateOrganization(id, data)
            : (data: any) => EJKNServiceSingleton.addOrganizationLists(data)
        }
      />
    );
  };
};
