/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useRef, useEffect } from "react";
import { createPortal } from "react-dom";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import SignatureCanvas from "react-signature-canvas";
import * as Yup from "yup";
import { useFormik } from "formik";

type Props = {
  show: boolean;
  handleClose: () => void;
  submitSignature: (data: any) => void;
  signature: string | null;
};

interface IDropPoint {
  id?: number;
  fullname: string;
  designation: string;
  location: string;
}

const modalsRoot = document.getElementById("root-modals") || document.body;

const signatureFormSchema = Yup.object().shape({
  fullname: Yup.string().required("Name is required!!"),
  designation: Yup.string().required("Designation is required!!"),
  location: Yup.string().required("Location is required!!"),
});

const SignatureModal = ({
  show,
  handleClose,
  submitSignature,
  signature = null,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [signatureData, setSignatureData] = useState<any>({
    fullname: "",
    designation: "",
    location: "",
  });

  async function handleFormSubmit(values: any) {}

  const formik = useFormik<IDropPoint>({
    initialValues: signatureData,
    validationSchema: signatureFormSchema,
    enableReinitialize: true,

    onSubmit: async (values) => {
      try {
        setLoading(true);
        await submitSignature(values);
        formik.resetForm();
      } catch (error) {
        console.log(error, "error");
      } finally {
        setLoading(false);
      }
    },
  });
  const { user } = useSelector(({ global }: any) => global);
  //   const signRef = useRef();
  let signRef = {} as any;

  async function verifyTicket() {
    if (signRef) {
      if (!signRef.isEmpty()) {
        try {
          setLoading(true);
          await submitSignature(
            signRef.getTrimmedCanvas().toDataURL("image/png")
          );
        } finally {
          setLoading(false);
        }
      }
    }
  }

  function onClear() {
    if (signRef) {
      signRef.clear();
    }
  }

  useEffect(() => {
    if (signature && Object.keys(signRef).length > 0) {
      if (signRef) {
        signRef.clear();
        signRef.fromDataURL(signature);
      }
    }
  }, [signature, signRef]);

  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-900px"
      show={show}
      onHide={handleClose}
      backdrop={true}
    >
      <form onSubmit={formik.handleSubmit} noValidate className="form">
        <div className="modal-header">
          <h2>Signature</h2>
        </div>

        <div className="modal-body py-lg-10 px-lg-10">
          {/* {signature && <img src={signature} />} */}
          {/* <SignatureCanvas
            penColor="black"
            canvasProps={{
              className: "signatureCanvas",
            }}
            ref={(ref) => {
              signRef = ref;
            }}
          /> */}
          <div className="row mb-6">
            <div className="col-sm-12 col-md-6">
              <label className="col-lg-4 col-form-label required fw-bold fs-6">
                Full Name
              </label>
              <input
                type="text"
                className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                placeholder="Full Name"
                {...formik.getFieldProps("fullname")}
              />
              {formik.touched.fullname && formik.errors.fullname && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.fullname}</div>
                </div>
              )}
            </div>
            <div className="col-sm-12 col-md-6">
              <label className="col-lg-4 col-form-label required fw-bold fs-6">
                Designation
              </label>
              <input
                type="text"
                className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                placeholder="designation"
                {...formik.getFieldProps("designation")}
              />
              {formik.touched.designation && formik.errors.designation && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.designation}
                  </div>
                </div>
              )}
            </div>
            <div className="col-sm-12">
              <label className="col-lg-4 col-form-label required fw-bold fs-6">
                Location
              </label>
              <textarea
                className="form-control form-control-solid mb-8"
                rows={3}
                placeholder="Location"
                {...formik.getFieldProps("location")}
              ></textarea>
              {formik.touched.location && formik.errors.location && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.location}</div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="modal-footer d-flex justify-content-between py-6 px-9">
          <div>
            <button
              type="button"
              className="btn btn-default"
              onClick={handleClose}
            >
              <span className="indicator-progress" style={{ display: "block" }}>
                Close
                {/* Please wait...{" "} */}
                {/* <span className="spinner-border spinner-border-sm align-middle ms-2"></span> */}
              </span>
            </button>
            {/* <button type="button" className="btn btn-danger" onClick={onClear}>
            <span className="indicator-progress" style={{ display: "block" }}>
              Clear
             
            </span>
          </button> */}
          </div>

          <button type="submit" className="btn btn-primary">
            {!loading && "Sign"}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                Please wait...{" "}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </Modal>,
    modalsRoot
  );
};

export { SignatureModal };
