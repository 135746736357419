import { useFormik } from "formik";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import * as Yup from "yup";
import { EJKNServiceSingleton } from "../../../../services/api/ejkns.service";
import { ToastrServiceSingleton } from "../../../../services/toastr/toastr.service";
import { IZone } from "../ZoneModel";

const zoneDetailsSchema = Yup.object().shape({
  name: Yup.string().required("Zone name is required"),
  state_id: Yup.string().required("State is required"),
  //   description: Yup.string().required("Description is required"),
});

const ZoneForm: any = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [states, setStates] = useState([]);

  const formik = useFormik<IZone>({
    initialValues: props.initialValues,
    validationSchema: zoneDetailsSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        if (values?.id && values.id) {
          await props.onFormSubmit(values.id, values);
          ToastrServiceSingleton.success("Successfull update zone");
        } else {
          await props.onFormSubmit(values);
          formik.resetForm();
          ToastrServiceSingleton.success("Successfull create zone");
        }
      } catch (error) {
        console.log(error, "error");
        ToastrServiceSingleton.error("Failed to create zone!");
      } finally {
        setLoading(false);
      }
    },
  });

  async function getStates() {
    const lists = await EJKNServiceSingleton.states();
    setStates(lists.data);
  }

  useEffect(() => {
    getStates();
  }, []);

  return (
    <div className="card mb-5 mb-xl-10">
      <div
        className="card-header border-0 cursor-pointer"
        role="button"
        data-bs-toggle="collapse"
        data-bs-target="#kt_account_profile_details"
        aria-expanded="true"
        aria-controls="kt_account_profile_details"
      >
        <div className="card-title m-0">
          <h3 className="fw-bolder m-0">
            {props.initialValues?.id ? "Edit" : "Add"} Zone
          </h3>
        </div>
      </div>
      {props.mainLoading ? (
        <Skeleton width={"100%"} height={30} />
      ) : (
        <form onSubmit={formik.handleSubmit} noValidate className="form">
          <div className="card-body border-top p-9">
            <div className="row mb-6">
              <label className="col-lg-4 col-form-label required fw-bold fs-6">
                Zone Name
              </label>

              <div className="col-lg-8">
                <div className="row">
                  <div className="col-lg-12 fv-row">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                      placeholder="Zone name"
                      {...formik.getFieldProps("name")}
                    />
                    {formik.touched.name && formik.errors.name && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.name}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-6">
              <label className="col-lg-4 col-form-label required fw-bold fs-6">
                Description
              </label>

              <div className="col-lg-8 fv-row">
                <textarea
                  className="form-control form-control-solid mb-8"
                  rows={3}
                  placeholder="Description"
                  {...formik.getFieldProps("description")}
                ></textarea>
                {formik.touched.description && formik.errors.description && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.description}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="row mb-6">
              <label className="col-lg-4 col-form-label required fw-bold fs-6">
                States
              </label>
              <div className="col-lg-8 fv-row">
                <select
                  className="form-select form-select-solid form-select-lg"
                  {...formik.getFieldProps("state_id")}
                >
                  <option value="">Select a state...</option>
                  {states.map((state: any) => (
                    <option key={state.id} value={state.id}>
                      {state.name}
                    </option>
                  ))}

                  {/* <option value="id">Hewlett Packard</option>
              <option value="msa">Dell</option> */}
                </select>
                {formik.touched.state_id && formik.errors.state_id && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.state_id}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="card-footer d-flex justify-content-end py-6 px-9">
            <button type="submit" className="btn btn-primary">
              <span className="indicator-progress" style={{ display: "block" }}>
                {!loading && "Submit"}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: "block" }}
                  >
                    Please wait...{" "}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </span>
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default ZoneForm;
