import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { EJKNServiceSingleton } from "../../../../services/api/ejkns.service";
import { ToastrServiceSingleton } from "../../../../services/toastr/toastr.service";
import { MenuComponent } from "../../../../_metronic/assets/ts/components";
import { KTSVG } from "../../../../_metronic/helpers";

import { ITicket } from "../TicketModel";
import { StatusDropDown } from "./StatusDropdown";

const ticketDetailsSchema = Yup.object().shape({
  title: Yup.string().required("Title is required!"),
  zone_id: Yup.string().required("Zone is required!!"),
  address: Yup.string().required("Address is required"),
  description: Yup.string().required("Description is required!"),
  district_id: Yup.string().required("District is required!"),
  latitude: Yup.string().required("Latitude is required!"),
  longitude: Yup.string().required("Longitude is required"),
});

export const EditTicketForm: any = ({
  ticket = {
    zone_id: "",
    address: "",
    description: "",
    district_id: "",
    latitude: "",
    longitude: "",
    title: "",
    number: "",
    status_id: 1,
    id: 0,
  },
  onOpenSign = () => {},
  updateTicketStatus = (status_id: number) => {},
  role = "",
  statuses = [],
}) => {
  const [loading, setLoading] = useState(false);
  const [disableField, setDisableField] = useState(false);
  const { zones } = useSelector((state: any) => state.zones);
  const { district } = useSelector((state: any) => state.district);
  const formik = useFormik<ITicket>({
    initialValues: ticket,
    validationSchema: ticketDetailsSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        await EJKNServiceSingleton.updateTicket(Number(values.id), values);
        ToastrServiceSingleton.success("Successfull update ticket details!");
      } catch (error) {
        ToastrServiceSingleton.error("Fail update ticket details!");
      } finally {
        setLoading(false);
      }
    },
  });

  function submitSign() {
    console.log(formik, "formik");
  }

  useEffect(() => {
    if (role) {
      if (role !== "Administrator") setDisableField(true);
      MenuComponent.reinitialization();
    }
  }, []);

  useEffect(() => {
    if (role && ticket) {
      MenuComponent.reinitialization();
    }
  }, [ticket, role]);

  return (
    <form onSubmit={formik.handleSubmit} noValidate className="form">
      <div className="card card-xl-stretch mb-xl-8">
        <div className="card-header border-0">
          <h3 className="card-title fw-bold text-dark">
            No: <span className="text-primary">{ticket && ticket.number}</span>
          </h3>
          <div className="card-toolbar">
            {(role == "Administrator" || role == "Pilot") && (
              <>
                <button
                  type="button"
                  className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
                  data-kt-menu-trigger="click"
                  data-kt-menu-placement="bottom-end"
                  data-kt-menu-flip="top-end"
                >
                  <KTSVG
                    path="/media/icons/duotune/general/gen024.svg"
                    className="svg-icon-2"
                  />
                </button>
                <StatusDropDown
                  onSignature={onOpenSign}
                  status_id={ticket.status_id}
                  updateTicketStatus={updateTicketStatus}
                  statuses={statuses}
                />
              </>
            )}
            {/* begin::Menu */}

            {/* end::Menu */}
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <label className="col-form-label fw-bold fs-6">Title</label>
              <input
                type="text"
                className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                placeholder="Title"
                {...formik.getFieldProps("title")}
                disabled={disableField}
              />
              {formik.touched.title && formik.errors.title && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.title}</div>
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <label className="col-form-label required fw-bold fs-6">
                District
              </label>
              <select
                className="form-select form-select-solid form-select-lg"
                {...formik.getFieldProps("district_id")}
                disabled={disableField}
              >
                <option value="">Select a district...</option>
                {district.map((d: any) => (
                  <option key={d.id} value={d.id}>
                    {d.name}
                  </option>
                ))}

                {/* <option value="id">Hewlett Packard</option>
              <option value="msa">Dell</option> */}
              </select>
              {formik.touched.district_id && formik.errors.district_id && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.district_id}
                  </div>
                </div>
              )}
            </div>
            <div className="col-sm-12 col-md-6">
              <label className="col-form-label fw-bold fs-6">Zone</label>
              <select
                className="form-select form-select-solid form-select-lg"
                {...formik.getFieldProps("zone_id")}
                disabled={disableField}
              >
                <option value="">Select a zone...</option>
                {zones.map((z: any) => (
                  <option key={z.id} value={z.id}>
                    {z.name}
                  </option>
                ))}

                {/* <option value="id">Hewlett Packard</option>
              <option value="msa">Dell</option> */}
                {formik.touched.zone_id && formik.errors.zone_id && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.zone_id}</div>
                  </div>
                )}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <label className="col-form-label required fw-bold fs-6">
                Latitude
              </label>
              <input
                type="text"
                className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                placeholder="Latitude"
                {...formik.getFieldProps("latitude")}
                disabled={disableField}
              />
              {formik.touched.latitude && formik.errors.latitude && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.latitude}</div>
                </div>
              )}
            </div>
            <div className="col-sm-12 col-md-6">
              <label className="col-form-label required fw-bold fs-6">
                Longitude
              </label>
              <input
                type="text"
                className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                placeholder="Postcode"
                {...formik.getFieldProps("longitude")}
                disabled={disableField}
              />
              {formik.touched.longitude && formik.errors.longitude && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.longitude}</div>
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <label className="col-form-label required fw-bold fs-6">
                Description
              </label>
              <textarea
                className="form-control form-control-solid mb-8"
                rows={5}
                placeholder="Description"
                {...formik.getFieldProps("description")}
                disabled={disableField}
              ></textarea>
              {formik.touched.description && formik.errors.description && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.description}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <label className="col-form-label required fw-bold fs-6">
                Address
              </label>
              <textarea
                className="form-control form-control-solid mb-8"
                rows={5}
                placeholder="Enter full address"
                {...formik.getFieldProps("address")}
                disabled={disableField}
              ></textarea>
              {formik.touched.address && formik.errors.address && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.address}</div>
                </div>
              )}
            </div>
          </div>

          <div className="d-flex justify-content-end py-6">
            {role == "Administrator" && (
              <button type="submit" className="btn btn-primary btn-sm">
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  {!loading && "Update Ticket"}
                  {loading && (
                    <span
                      className="indicator-progress"
                      style={{ display: "block" }}
                    >
                      Please wait...{" "}
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </span>
              </button>
            )}
          </div>
        </div>
      </div>

      {/* <div className="row">
          <div className="col-sm-12">
            <p className="text-dark-900 fw-bold fs-5 mt-1 mb-7">
              
            </p>
          </div>
        </div> */}
    </form>
  );
};
