import React, { useEffect, useRef, useState } from "react";
import Map from "@arcgis/core/Map";
import MapView from "@arcgis/core/views/MapView";
import * as reactiveUtils from "@arcgis/core/core/reactiveUtils";

import { StyleSheet } from "@react-pdf/renderer";
import { ArcGIS } from "../../../../services/arcgis/map.service";
import { EJKNServiceSingleton } from "../../../../services/api/ejkns.service";
import { jsPDF } from "jspdf";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { DateUtilsServiceSingleton } from "../../../../services/utilities/data/date-utils.service";
import { useDispatch, useSelector } from "react-redux";
// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#fff",
    width: "100%",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    width: "100%",
  },
  doc: {
    width: "100%",
  },
});

const arcgis = ArcGIS.getInstance();

function PDFDocs({
  ticket,
  dropPoints,
  handleClose,
  statuses,
  setLoadingGlobal,
}: any) {
  const dispatch = useDispatch();
  const { zones } = useSelector(({ zones }: any) => zones);
  const { district } = useSelector(({ district }: any) => district);

  const [showPhotosOptions, setShowPhotosOptions] = useState(true);
  const [showLoadMap, setShowLoadMap] = useState(false);
  const [photos, setPhotos] = useState([]);

  const [selectedPhotos, setSelectedPhotos] = useState([]);
  const viewDiv = useRef<any>(null);
  const pdf = new jsPDF("portrait", "pt", "a4");

  const width = pdf.internal.pageSize.getWidth() - 15;
  const height = pdf.internal.pageSize.getHeight();
  let ticketLayer = false;
  let dropLayer = false;

  function getZoneName(id: Number) {
    return zones.reduce((final: any, curr) => {
      if (curr.id === id) {
        final = curr.name;
      }

      return final;
    }, "N/A");
  }

  function getDistrictName(id: Number) {
    return district.reduce((final: any, curr) => {
      if (curr.id === id) {
        final = curr.name;
      }

      return final;
    }, "N/A");
  }

  function getStatus(id: Number) {
    return statuses.reduce((final: any, curr) => {
      if (curr.id === id) {
        final = curr.name;
      }

      return final;
    }, "N/A");
  }

  useEffect(() => {
    const processImageData = async () => {
      let droppoints = dropPoints.reduce((final, curr) => {
        final.push(curr.id);
        return final;
      }, []);
      const mediaData = await EJKNServiceSingleton.mediaByDropPoints({
        droppoints,
      });

      const mediaDataFormatted = mediaData.data.reduce((final, curr) => {
        final.push({ ...curr, selected: false });
        return final;
      }, []);
      setPhotos(mediaDataFormatted);
    };
    if (dropPoints.length > 0) {
      processImageData();
    } else {
      onGenerateReport();
    }
  }, [dropPoints]);

  useEffect(() => {
    if (showLoadMap) {
      if (!arcgis.map) {
        arcgis.map = new Map({
          basemap: "satellite",
        });
      }

      if (!arcgis.view) {
        arcgis.view = new MapView({
          container: viewDiv.current as HTMLDivElement,
          map: arcgis.map,
          center: [101.642, 2.9279],
          zoom: 11,
        });
      } else {
        arcgis.view.container = viewDiv.current as HTMLDivElement;
      }

      arcgis.map.add(arcgis.Layer);

      const handleData = async () => {
        await arcgis.initializeTicketLayerV2([ticket]);
        await arcgis.initializeDropPointLayerV2(dropPoints);
        return true;
      };

      arcgis.view.when(() => {
        handleData().then(() => {
          arcgis.view
            .whenLayerView(arcgis.dropLayer)
            .then(function (layerView) {
              reactiveUtils
                .whenOnce(() => !layerView.updating)
                .then(() => {
                  // if(dropLayer)
                  dropLayer = true;

                  if (dropLayer && ticketLayer) {
                    generatePdf();
                  }
                });
            });

          arcgis.view
            .whenLayerView(arcgis.ticketLayer)
            .then(function (layerView) {
              reactiveUtils
                .whenOnce(() => !layerView.updating)
                .then(() => {
                  // if(dropLayer)
                  ticketLayer = true;

                  if (dropLayer && ticketLayer) {
                    generatePdf();
                  }
                });
            });
        });
      });
    }
    // handleData();
  }, [showLoadMap]);

  const legendClick = () => {
    if (arcgis.LegendWidget) {
      arcgis.LegendWidget.visible = !arcgis.LegendWidget.visible;
    }
  };

  function onSelectPhoto(photo) {
    setPhotos((prevPhotos) => {
      return prevPhotos.map((p) => {
        if (p.id === photo.id) {
          return { ...p, selected: !p.selected };
        } else return p;
      });
    });
  }

  const toDataURL = (url) =>
    fetch(url)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
      );

  async function reduce_image_file_size(
    base64Str,
    MAX_WIDTH = 450,
    MAX_HEIGHT = 450
  ) {
    let resized_base64 = await new Promise((resolve) => {
      let img = new Image();
      img.src = base64Str;
      img.onload = () => {
        let canvas = document.createElement("canvas");
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        let ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        resolve(canvas.toDataURL()); // this will return base64 image results after resize
      };
    });
    return resized_base64;
  }

  async function onGenerateReport() {
    setLoadingGlobal(true);
    const selectedPhotos = photos.filter((p) => p.selected);
    let allPhotos = [];
    selectedPhotos.forEach((photos) =>
      allPhotos.push(
        toDataURL(
          `${process.env.REACT_APP_STORAGE_URL}/${photos.file_name}.${photos.file_type}`
        )
      )
    );

    let formatted = await Promise.all(allPhotos);

    let allPhotosCompress = [];

    formatted.forEach((p) => allPhotosCompress.push(reduce_image_file_size(p)));

    let compressPhoto = await Promise.all(allPhotosCompress);

    setSelectedPhotos(compressPhoto);
    setShowPhotosOptions(false);
    setShowLoadMap(true);
  }

  async function generatePdf() {
    setTimeout(async () => {
      const data = await arcgis.view.takeScreenshot();
      // let mediasAPI = [];
      let droppoints = dropPoints.reduce((final, curr) => {
        final.push(curr.id);
        return final;
      }, []);

      const mediaData = await EJKNServiceSingleton.mediaByDropPoints({
        droppoints,
      });

      setPhotos(mediaData.data);
      // const imga: any = await document.querySelector("#html img");
      const htm: any = document.querySelector("#html");
      const mapContainer: any = document.querySelector("#map-container");
      const myMap: any = document.querySelector("#my-map");

      myMap.style.display = "none";
      htm.style.display = "";

      let imgelement = document.createElement("img");
      imgelement.src = data.dataUrl;
      imgelement.style.height = "100%";
      imgelement.style.width = "100%";
      mapContainer.appendChild(imgelement);

      await pdf.html(htm);
      pdf.save(`${ticket.number}.pdf`);
      setLoadingGlobal(false);
      handleClose();
    }, 2000);
  }

  function returnTemplate() {
    return showPhotosOptions ? (
      <div className="row">
        <div className="col-sm-10 mb-4">
          Please select images that you want to include in the report
        </div>
        <div className="col-sm-2 mb-4">
          <button
            className="btn btn-primary float"
            onClick={() => onGenerateReport()}
          >
            Continue
          </button>
        </div>
        {photos.map((photo) => {
          return (
            <div
              className="col-sm-12 col-md-6 mb-4"
              key={photo.id}
              style={{ cursor: "pointer", position: "relative" }}
              onClick={() => onSelectPhoto(photo)}
            >
              {photo.selected && (
                <i
                  className="bi bi-check-circle"
                  style={{
                    position: "absolute",
                    top: "5px",
                    right: "20px",
                    zIndex: 100,
                    color: "green",
                    fontSize: "20px",
                    backgroundColor: "#fff",
                    borderRadius: "50%",
                  }}
                />
              )}

              <img
                src={`${process.env.REACT_APP_STORAGE_URL}/${photo.file_name}.${photo.file_type}`}
                style={{
                  width: "100%",
                  height: "250px",
                  borderRadius: "5px",
                  cursor: "pointer",
                  borderWidth: photo.selected ? "5px" : "1px",
                  borderColor: photo.selected ? "green" : "black",
                  borderStyle: "solid",
                }}
              ></img>
            </div>
          );
        })}
      </div>
    ) : (
      <div style={{ width: "100%" }}>
        <div id="my-map" style={{ width: "100%", height: "100%" }}>
          <div ref={viewDiv} className="col-md-12 map-container"></div>
        </div>
        {/* <div > */}
        <div
          id="html"
          style={{
            display: "none",
            padding: "5px",
            width: width,
            margin: "auto",
          }}
        >
          <div style={{ width: width }}>
            <div
              style={{
                width: width * 0.2,
                display: "inline-block",
                verticalAlign: "top",
              }}
            >
              <img
                alt="Logo"
                src={toAbsoluteUrl("/media/logos/core-data-logo.png")}
                className="app-sidebar-logo-default"
                style={{ height: "100%" }}
              />
            </div>
            <div
              style={{
                width: width * 0.5,
                display: "inline-block",
                verticalAlign: "top",
              }}
            >
              <p className="mb-0" style={{ fontSize: "10px" }}>
                COREDATA SDN BHD (1283806-X)
              </p>
              <p className="mb-0" style={{ fontSize: "10px" }}>
                5-16, Infinity Tower
              </p>
              <p className="mb-0" style={{ fontSize: "10px" }}>
                Jalan SS6/3, SS6 Kelana Jaya,
              </p>
              <p className="mb-0" style={{ fontSize: "9px" }}>
                47301 Petaling Jaya, Selangor
              </p>
              <p className="mb-0" style={{ fontSize: "10px" }}>
                Office: +603-74920313
              </p>
            </div>
            <div
              style={{
                width: width * 0.3,
                display: "inline-block",
                verticalAlign: "top",
              }}
            >
              <p className="mb-0" style={{ fontSize: "16px" }}>
                SERVICE REPORT
              </p>
              <p className="mb-0" style={{ fontSize: "12px" }}>
                REF NO: {ticket && ticket.number}
              </p>
            </div>
          </div>
          <div style={{ width: width, marginTop: "5px" }}>
            <div
              style={{
                backgroundColor: "orange",
                color: "black",
                padding: "5px",
                fontWeight: "bold",
                borderColor: "black",
                borderWidth: "1px",
                borderStyle: "solid",
                fontSize: "10px",
              }}
            >
              PILOT INFORMATION
            </div>
          </div>
          <div style={{ width: width }}>
            <div
              style={{
                width: width * 0.6,
                display: "inline-block",
                borderColor: "black",
                borderWidth: "1px",
                borderStyle: "solid",
              }}
            >
              <div
                style={{
                  color: "black",
                  padding: "5px",
                  fontSize: "10px",
                }}
              >
                NAME
              </div>
            </div>
            <div
              style={{
                width: width * 0.4,
                display: "inline-block",
                borderRightColor: "black",
                borderRightWidth: "1px",
                borderRightStyle: "solid",
                borderBottomColor: "black",
                borderBottomWidth: "1px",
                borderBottomStyle: "solid",
                borderTopColor: "black",
                borderTopWidth: "1px",
                borderTopStyle: "solid",
              }}
            >
              <div
                style={{
                  color: "black",
                  padding: "5px",
                  fontSize: "10px",
                }}
              >
                START DATE :{" "}
                {ticket &&
                  DateUtilsServiceSingleton.convertDateDefault(
                    ticket.created_at
                  )}
              </div>
            </div>
          </div>

          <div style={{ width: width, marginTop: "5px" }}>
            <div
              style={{
                backgroundColor: "orange",
                color: "black",
                padding: "5px",
                fontWeight: "bold",
                borderColor: "black",
                borderWidth: "1px",
                borderStyle: "solid",
                fontSize: "10px",
              }}
            >
              LOCALITY INFORMATION
            </div>
          </div>
          <div style={{ width: width }}>
            <div
              style={{
                width: width * 0.2,
                display: "inline-block",
                borderColor: "black",
                borderWidth: "1px",
                borderStyle: "solid",
                borderTop: "0px",
                borderTopColor: "none",
                borderTopWidth: "0px",
              }}
            >
              <div
                style={{
                  color: "black",
                  padding: "5px",
                  fontSize: "10px",
                }}
              >
                ADDRESS
              </div>
            </div>
            <div
              style={{
                width: width * 0.8,
                display: "inline-block",
                borderColor: "black",
                borderWidth: "1px",
                borderStyle: "solid",
                borderTop: "0px",
                borderTopColor: "none",
                borderTopStyle: "none",
                borderLeft: "0px",
                borderLeftColor: "none",
                borderLeftStyle: "none",
                borderRight: "1px",
                borderRightColor: "black",
                borderRightStyle: "solid",
              }}
            >
              <div
                style={{
                  color: "black",
                  padding: "5px",
                  fontSize: "10px",
                }}
              >
                {ticket && ticket.address}
              </div>
            </div>
          </div>
          <div style={{ width: width }}>
            <div
              style={{
                width: width * 0.5,
                display: "inline-block",
                // borderColor: "black",
                // borderWidth: "1px",
                // borderStyle: "solid",
              }}
            >
              <div
                style={{
                  width: "30%",
                  display: "inline-block",
                  borderLeftColor: "black",
                  borderLeftWidth: "1px",
                  borderLeftStyle: "solid",
                }}
              >
                <div
                  style={{
                    color: "black",
                    padding: "5px",
                    fontSize: "10px",
                  }}
                >
                  LONGITUDE
                </div>
              </div>
              <div
                style={{
                  width: "70%",
                  display: "inline-block",
                  // borderColor: "black",
                  // borderWidth: "1px",
                  // borderStyle: "solid",
                  // borderTop: "0px",
                  // borderTopColor: "none",
                  // borderTopStyle: "none",
                  borderLeft: "1px",
                  borderLeftColor: "black",
                  borderLeftStyle: "solid",
                }}
              >
                <div
                  style={{
                    color: "black",
                    padding: "5px",
                    fontSize: "10px",
                  }}
                >
                  {ticket && ticket.longitude}
                </div>
              </div>
            </div>
            <div
              style={{
                width: width * 0.5,
                display: "inline-block",
                // borderColor: "black",
                // borderBottom: "1px",
                // borderStyle: "solid",
                // borderTop: "0px",
                // borderTopColor: "none",
                // borderTopStyle: "none",
                borderLeft: "1px",
                borderLeftColor: "black",
                borderLeftStyle: "solid",
                borderRight: "1px",
                borderRightColor: "black",
                borderRightStyle: "solid",
              }}
            >
              <div
                style={{
                  width: "30%",
                  display: "inline-block",
                  borderRightColor: "black",
                  borderRight: "1px",
                  borderRightStyle: "solid",
                  // borderTop: "0px",
                  // borderTopColor: "none",
                  // borderTopStyle: "none",
                  // borderLeft: "1px",
                  // borderLeftColor: "black",
                  // borderLeftStyle: "solid",
                }}
              >
                <div
                  style={{
                    color: "black",
                    padding: "5px",
                    fontSize: "10px",
                  }}
                >
                  LATITUDE
                </div>
              </div>
              <div
                style={{
                  width: "70%",
                  display: "inline-block",
                  // borderRightColor: "black",
                  // // borderWidth: "1px",
                  // borderRightStyle: "solid",
                  // borderRight: "1px",
                  // borderTop: "0px",
                  // borderTopColor: "none",
                  // borderTopStyle: "none",
                  borderLeft: "1px",
                  borderLeftColor: "black",
                  borderLeftStyle: "none",
                }}
              >
                <div
                  style={{
                    color: "black",
                    padding: "5px",
                    fontSize: "10px",
                  }}
                >
                  {ticket && ticket.latitude}
                </div>
              </div>
            </div>
          </div>
          {/*  
            ,,,
          */}
          <div style={{ width: width }}>
            <div
              style={{
                width: "50%",
                display: "inline-block",
                borderColor: "black",
                borderWidth: "1px",
                borderStyle: "solid",
              }}
            >
              <div
                style={{
                  width: "30%",
                  display: "inline-block",
                  // borderRightColor: "black",
                  // borderRight: "1px",
                  // borderRightStyle: "solid",
                }}
              >
                <div
                  style={{
                    color: "black",
                    padding: "5px",
                    fontSize: "10px",
                  }}
                >
                  ZONE/MUKIM
                </div>
              </div>
              <div
                style={{
                  width: "70%",
                  display: "inline-block",
                  borderLeftColor: "black",
                  borderWidth: "1px",
                  borderLeftStyle: "solid",
                }}
              >
                <div
                  style={{
                    color: "black",
                    padding: "5px",
                    fontSize: "10px",
                  }}
                >
                  {ticket && getZoneName(ticket.zone_id)}
                </div>
              </div>
            </div>
            <div
              style={{
                width: "50%",
                display: "inline-block",
                // borderBottomColor: "black",
                // borderBottomWidth: "1px",
                // borderBottomStyle: "solid",
              }}
            >
              <div
                style={{
                  width: "40%",
                  display: "inline-block",
                  borderRightColor: "black",
                  borderRight: "1px",
                  borderRightStyle: "solid",
                  borderBottomColor: "black",
                  borderBottom: "1px",
                  borderBottomStyle: "solid",
                  borderTopColor: "black",
                  borderTop: "1px",
                  borderTopStyle: "solid",
                }}
              >
                <div
                  style={{
                    color: "black",
                    padding: "5px",
                    fontSize: "10px",
                  }}
                >
                  DISTRICT/DAERAH
                </div>
              </div>
              <div
                style={{
                  width: "60%",
                  display: "inline-block",
                  borderRightColor: "black",
                  borderRight: "1px",
                  borderRightStyle: "solid",
                  borderBottomColor: "black",
                  borderBottom: "1px",
                  borderBottomStyle: "solid",
                  borderTopColor: "black",
                  borderTop: "1px",
                  borderTopStyle: "solid",
                }}
              >
                <div
                  style={{
                    color: "black",
                    padding: "5px",
                    fontSize: "10px",
                  }}
                >
                  {ticket && getDistrictName(ticket.district_id)}
                </div>
              </div>
            </div>
          </div>

          <div style={{ width: width, marginTop: "5px" }}>
            <div
              style={{
                backgroundColor: "orange",
                color: "black",
                padding: "5px",
                fontWeight: "bold",
                borderColor: "black",
                borderWidth: "1px",
                borderStyle: "solid",
                fontSize: "10px",
                width: width,
                // marginTop: height,
              }}
            >
              MAP LOCATION PINPOINT
            </div>
            <div
              id="map-container"
              style={{
                width: width,
                height: `${height - 300}px`,
                borderColor: "black",
                borderWidth: "1px",
                borderStyle: "solid",
                padding: "10px",
                pageBreakAfter: "always",
              }}
            ></div>
          </div>
          <div
            style={{
              width: width,
              minHeight: height * Math.ceil(selectedPhotos.length / 2),
            }}
          >
            <div style={{ width: width }}>
              <div
                style={{
                  backgroundColor: "orange",
                  color: "black",
                  padding: "5px",
                  fontWeight: "bold",
                  borderColor: "black",
                  borderWidth: "1px",
                  borderStyle: "solid",
                  fontSize: "10px",
                  marginTop: "20px",
                }}
              >
                PHOTOS ON-SITE
              </div>
            </div>
            <div
              style={{
                width: width,
                display: "inline-block",
                borderColor: "black",
                borderWidth: "1px",
                borderStyle: "solid",
                minHeight: "100px",
              }}
            >
              {selectedPhotos.map((media, i) => (
                <div
                  key={i}
                  style={{
                    color: "black",
                    padding: "5px",
                    fontSize: "10px",
                    minHeight: "50px",
                  }}
                >
                  <img
                    src={media}
                    style={{
                      width: "100%",
                      height: `${(height - 60) / 2}px`,
                      borderRadius: "5px",
                      cursor: "pointer",
                      borderWidth: "1px",
                      borderColor: "black",
                      borderStyle: "solid",
                      marginTop: i % 2 == 0 && i > 1 ? "20px" : 0,
                      marginBottom: i % 2 != 0 && i > 1 ? "20px" : 0,
                    }}
                  />
                </div>
              ))}
            </div>
          </div>

          <div style={{ width: width }}>
            <div
              style={{
                width: width,
                display: "inline-block",
                borderColor: "black",
                borderWidth: "1px",
                borderStyle: "solid",

                fontWeight: "bold",
              }}
            >
              <div
                style={{
                  color: "black",
                  padding: "5px",
                  fontSize: "10px",
                  backgroundColor: "orange",
                }}
              >
                PKD/PBT REMARKS ON-SITE
              </div>
            </div>
          </div>
          <div
            style={{
              width: width,
              display: "inline-block",
              borderColor: "black",
              borderWidth: "1px",
              borderStyle: "solid",
            }}
          >
            <div
              style={{
                color: "black",
                padding: "5px",
                fontSize: "10px",
                minHeight: `${(height - 85) / 2}px`,
              }}
            ></div>
          </div>

          <div style={{ width: width, marginTop: "5px" }}>
            <div
              style={{
                width: width,
                display: "inline-block",
                borderColor: "black",
                borderWidth: "1px",
                borderStyle: "solid",

                fontWeight: "bold",
              }}
            >
              <div
                style={{
                  color: "black",
                  padding: "5px",
                  fontSize: "10px",
                  backgroundColor: "orange",
                }}
              >
                JKNS REMARKS
              </div>
            </div>
          </div>
          <div
            style={{
              width: width,
              display: "inline-block",
              borderColor: "black",
              borderWidth: "1px",
              borderStyle: "solid",
            }}
          >
            <div
              style={{
                color: "black",
                padding: "5px",
                fontSize: "10px",
                minHeight: `${(height - 85) / 2}px`,
              }}
            ></div>
          </div>

          <div style={{ width: width, pageBreakAfter: "always" }}>
            <div
              style={{
                backgroundColor: "orange",
                color: "black",
                padding: "5px",
                fontWeight: "bold",
                borderColor: "black",
                borderWidth: "1px",
                borderStyle: "solid",
                fontSize: "10px",
                marginTop: "20px",
              }}
            >
              STATUS
            </div>
          </div>
          <div style={{ width: width }}>
            <div
              style={{
                width: width,
                display: "inline-block",
                borderColor: "black",
                borderWidth: "1px",
                borderStyle: "solid",
              }}
            >
              <div
                style={{
                  color: "black",
                  padding: "5px",
                  fontSize: "10px",
                }}
              >
                {ticket && getStatus(ticket.status_id)}
              </div>
            </div>
          </div>

          <div style={{ width: width, marginTop: "20px" }}>
            <div
              style={{
                width: width,
                display: "inline-block",
                borderColor: "black",
                borderWidth: "1px",
                borderStyle: "solid",
                borderBottom: "0px",
                fontWeight: "bold",
              }}
            >
              <div
                style={{
                  color: "black",
                  padding: "5px",
                  fontSize: "10px",
                  backgroundColor: "orange",
                }}
              >
                AUTHORIZATION
              </div>
            </div>
          </div>
          <div
            style={{
              width: width,
              display: "block",
              borderColor: "black",
              borderWidth: "1px",
              borderStyle: "solid",
            }}
          >
            <div
              style={{
                color: "black",
                padding: "5px",
                fontSize: "10px",
                width: "33.33%",
                display: "inline-block",
                textAlign: "center",
                borderRight: "1px",
                borderRightColor: "black",
                borderRightStyle: "solid",
              }}
            >
              PILOT
            </div>
            <div
              style={{
                color: "black",
                padding: "5px",
                fontSize: "10px",
                width: "33.33%",
                textAlign: "center",
                borderRight: "1px",
                borderRightColor: "black",
                borderRightStyle: "solid",
                display: "inline-block",
              }}
            >
              PKD/PBT
            </div>
            <div
              style={{
                color: "black",
                padding: "5px",
                fontSize: "10px",
                width: "33.33%",
                textAlign: "center",
                // borderRight: "1px",
                // borderRightColor: "black",
                // borderStyle: "solid",
                display: "inline-block",
              }}
            >
              JKNS
            </div>
          </div>
          <div
            style={{
              width: width,
              display: "inline-block",
              borderColor: "black",
              borderWidth: "1px",
              borderStyle: "solid",
              verticalAlign: "bottom",
              borderTopColor: "",
              borderTopStyle: "none",
              borderTopWidth: "0px",
            }}
          >
            <div
              style={{
                color: "black",
                padding: "5px",
                fontSize: "10px",
                width: "33.33%",
                display: "inline-block",
                textAlign: "center",
                borderRight: "1px",
                borderRightColor: "black",
                borderRightStyle: "solid",
                minHeight: "100px",
              }}
            >
              {/* <hr /> */}
            </div>
            <div
              style={{
                color: "black",
                padding: "5px",
                fontSize: "10px",
                width: "33.33%",
                textAlign: "center",
                borderRight: "1px",
                borderRightColor: "black",
                borderRightStyle: "solid",
                display: "inline-block",
                minHeight: "100px",
                verticalAlign: "bottom",
              }}
            >
              {/* <hr /> */}
            </div>
            <div
              style={{
                color: "black",
                padding: "5px",
                fontSize: "10px",
                width: "33.33%",
                textAlign: "center",
                // borderRight: "1px",
                // borderColor: "black",
                // borderStyle: "solid",
                display: "inline-block",
                minHeight: "100px",
                verticalAlign: "bottom",
              }}
            >
              {/* <hr /> */}
            </div>
          </div>
          <div
            style={{
              width: width,
              display: "inline-block",
              borderColor: "black",
              borderWidth: "1px",
              borderStyle: "solid",
              borderTop: "0px",
              borderTopColor: "none",
              borderTopStyle: "none",
            }}
          >
            <div
              style={{
                color: "black",
                padding: "5px",
                fontSize: "10px",
                width: "33.33%",
                display: "inline-block",
                borderRight: "1px",
                borderRightColor: "black",
                borderRightStyle: "solid",
              }}
            >
              NAME :
            </div>
            <div
              style={{
                color: "black",
                padding: "5px",
                fontSize: "10px",
                width: "33.33%",
                borderRight: "1px",
                borderRightColor: "black",
                borderRightStyle: "solid",
                display: "inline-block",
              }}
            >
              NAME :
            </div>
            <div
              style={{
                color: "black",
                padding: "5px",
                fontSize: "10px",
                width: "33.33%",
                // borderRight: "1px",
                // borderColor: "black",
                // borderStyle: "solid",
                display: "inline-block",
              }}
            >
              NAME :
            </div>
          </div>
          <div
            style={{
              width: width,
              display: "inline-block",
              borderColor: "black",
              borderWidth: "1px",
              borderStyle: "solid",
              borderTop: "0px",
              borderTopColor: "none",
              borderTopStyle: "none",
            }}
          >
            <div
              style={{
                color: "black",
                padding: "5px",
                fontSize: "10px",
                width: "33.33%",
                display: "inline-block",
                borderRight: "1px",
                borderRightColor: "black",
                borderRightStyle: "solid",
              }}
            >
              DESIGNATION :
            </div>
            <div
              style={{
                color: "black",
                padding: "5px",
                fontSize: "10px",
                width: "33.33%",
                borderRight: "1px",
                borderRightColor: "black",
                borderRightStyle: "solid",
                display: "inline-block",
              }}
            >
              DESIGNATION :
            </div>
            <div
              style={{
                color: "black",
                padding: "5px",
                fontSize: "10px",
                width: "33.33%",
                // borderRight: "1px",
                // borderRightColor: "black",
                // borderRightStyle: "solid",
                display: "inline-block",
              }}
            >
              DESIGNATION :
            </div>
          </div>
          <div
            style={{
              width: width,
              display: "inline-block",
              borderColor: "black",
              borderWidth: "1px",
              borderStyle: "solid",
              borderTop: "0px",
              borderTopColor: "none",
              borderTopStyle: "none",
            }}
          >
            <div
              style={{
                color: "black",
                padding: "5px",
                fontSize: "10px",
                width: "33.33%",
                display: "inline-block",
                borderRight: "1px",
                borderRightColor: "black",
                borderRightStyle: "solid",
              }}
            >
              DATE :
            </div>
            <div
              style={{
                color: "black",
                padding: "5px",
                fontSize: "10px",
                width: "33.33%",
                borderRight: "1px",
                borderRightColor: "black",
                borderRightStyle: "solid",
                display: "inline-block",
              }}
            >
              DATE :
            </div>
            <div
              style={{
                color: "black",
                padding: "5px",
                fontSize: "10px",
                width: "33.34%",
                // borderRight: "1px",
                // borderColor: "black",
                // borderStyle: "solid",
                display: "inline-block",
              }}
            >
              DATE :
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>
    );
  }

  return returnTemplate();
}

export default PDFDocs;
