import { store } from "../../store";

class AreaUtilsService {
  private static instance: AreaUtilsService;
  private _organizations: any;
  private _zone: any;
  private _district: any;

  private constructor() {}

  public static getInstance(): AreaUtilsService {
    if (!AreaUtilsService.instance) {
      AreaUtilsService.instance = new AreaUtilsService();
      AreaUtilsService.instance._listenChange();
    }

    return AreaUtilsService.instance;
  }

  private _listenChange() {
    store.subscribe(() => this._getState());
  }

  private _getState() {
    const state = store.getState();
    this._organizations = state.organizations.organization;
    this._zone = state.zones.zones;
    this._district = state.district.district;
  }

  convertOrganizationById(id: number) {
    this._organizations = store.getState().organizations.organization;
    return this._organizations.reduce((final: any, curr: any) => {
      if (curr.id === id) final = curr.name;
      return final;
    }, "N/A");
  }

  convertZoneById(id: number) {
    this._zone = store.getState().zones.zones;
    return this._zone.reduce((final: any, curr: any) => {
      if (curr.id === id) final = curr.name;
      return final;
    }, "N/A");
  }

  convertDistrictById(id: number) {
    this._district = store.getState().district.district;
    return this._district.reduce((final: any, curr: any) => {
      if (curr.id === id) final = curr.name;
      return final;
    }, "N/A");
  }
}

export const AreaUtilsServiceSingleton = AreaUtilsService.getInstance();
