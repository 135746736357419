import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { EJKNServiceSingleton } from "../../../../services/api/ejkns.service";
import { ToastrServiceSingleton } from "../../../../services/toastr/toastr.service";
import { IStatus, statusInitialValues } from "../StatusModel";

export const withStatusFormLogic = (WrappedComponent: any) => {
  return () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [initialValues, setInitialValues] =
      useState<IStatus>(statusInitialValues);

    useEffect(() => {
      if (id) {
        const getOrgDetails = async (id: number) => {
          try {
            setLoading(true);
            const data = await EJKNServiceSingleton.statusById(id);
            setInitialValues(data.data);
          } catch (error) {
            ToastrServiceSingleton.error("Failed to fetch status details");
          } finally {
            setLoading(false);
          }
        };
        getOrgDetails(Number(id));
      } else {
        setInitialValues(statusInitialValues);
      }
    }, [id]);

    return (
      <WrappedComponent
        initialValues={initialValues}
        mainLoading={loading}
        onFormSubmit={
          id
            ? (id: number, data: any) =>
                EJKNServiceSingleton.updateStatus(id, data)
            : (data: any) => EJKNServiceSingleton.createStatus(data)
        }
      />
    );
  };
};
