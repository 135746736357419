/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useRef, useEffect } from "react";
import { createPortal } from "react-dom";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import SignatureCanvas from "react-signature-canvas";
import * as Yup from "yup";
import { useFormik } from "formik";
import PDFDocs from "./PDFDocs";
import { PDFViewer } from "@react-pdf/renderer";
import { LoaderComponent } from "../../../pages/components/LoaderComponent";

type Props = {
  show: boolean;
  handleClose: () => void;
  ticket: any;
  dropPoints: any;
  statuses: any[];
  setLoadingGlobal: any;
};

interface IDropPoint {
  id?: number;
  fullname: string;
  designation: string;
  location: string;
}

const modalsRoot = document.getElementById("root-modals") || document.body;

const signatureFormSchema = Yup.object().shape({
  fullname: Yup.string().required("Name is required!!"),
  designation: Yup.string().required("Designation is required!!"),
  location: Yup.string().required("Location is required!!"),
});

const PdfModal = ({
  show,
  handleClose,
  ticket,
  dropPoints,
  statuses,
  setLoadingGlobal,
}: Props) => {
  return createPortal(
    <Modal
      id="kt_modal_pdf_viewer"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-900px"
      show={show}
      onHide={handleClose}
      backdrop={true}
    >
      <div>
        <div className="modal-header">
          <h2>Reporting</h2>
        </div>

        <div className="modal-body py-lg-10 px-lg-10">
          {/* {signature && <img src={signature} />} */}
          {/* <SignatureCanvas
            penColor="black"
            canvasProps={{
              className: "signatureCanvas",
            }}
            ref={(ref) => {
              signRef = ref;
            }}
          /> */}
          {/* <PDFViewer width={"100%"} height={"100%"}> */}
          {}
          <PDFDocs
            ticket={ticket}
            dropPoints={dropPoints}
            handleClose={handleClose}
            statuses={statuses}
            setLoadingGlobal={setLoadingGlobal}
          />
          {/* </PDFViewer> */}
        </div>
      </div>
    </Modal>,
    modalsRoot
  );
};

export { PdfModal };
