import React, { useEffect, useState } from "react";
import { EJKNServiceSingleton } from "../../../../services/api/ejkns.service";
import { ToastrServiceSingleton } from "../../../../services/toastr/toastr.service";
import { DateUtilsServiceSingleton } from "../../../../services/utilities/data/date-utils.service";
import { KTSVG } from "../../../../_metronic/helpers";
import { ConfirmationModal } from "../../widgets/components/ConfirmationModal";

export const PermitTable = ({
  permits = [] as any[],
  onEdit = (data: any) => {},
  handleDeletionLocally = (id: number) => {},
  role = "",
}) => {
  const [showModal, setShowModal] = useState(false);
  const [deleteId, setDeleteId] = useState<{
    id: number;
    status: string;
  } | null>(null);

  function onTriggerDelete(id: number) {
    setShowModal(true);
    setDeleteId({
      id: id,
      status: "draft",
    });
  }

  function confirmDelete() {
    setDeleteId((value: any) => ({ id: value.id, status: "delete" }));
  }

  function convertDate(date: string) {
    return DateUtilsServiceSingleton.convertDateDefault(date);
  }

  async function deletePermit(id: number) {
    try {
      const deletedData: any = await EJKNServiceSingleton.deletePermit(id);
      handleDeletionLocally(deletedData.data.id);
      setShowModal(false);
      setDeleteId(null);
      ToastrServiceSingleton.success("Success delete permit!");
    } catch (error) {
      ToastrServiceSingleton.success("Fail to delete permit!");
    }
  }

  useEffect(() => {
    if (deleteId?.status === "delete") {
      deletePermit(deleteId.id);
    }
  }, [deleteId]);

  return (
    <>
      <div className="table-responsive">
        {/* begin::Table */}
        <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
          {/* begin::Table head */}
          <thead>
            <tr className="fw-bold text-muted">
              <th className="text-dark">No</th>
              <th className="text-dark">Name</th>
              <th className="text-dark">Description</th>
              <th className="text-dark">Permit Type</th>
              <th className="text-dark">Date</th>
              {/* <th className="text-dark">Pilot</th> */}
              <th className="text-dark text-end">Action</th>
            </tr>
          </thead>
          {/* end::Table head */}
          {/* begin::Table body */}
          <tbody>
            {permits.map((permit: any, i: number) => (
              <tr key={permit.id}>
                <td>
                  <span className="text-dark fw-bold text-hover-primary fs-6">
                    {i + 1}
                  </span>
                </td>
                <td>
                  <a
                    href="#"
                    className="text-muted fw-bold text-hover-primary d-block mb-1 fs-6"
                  >
                    {permit?.name}
                  </a>
                </td>
                <td>
                  <a
                    href="#"
                    className="text-muted fw-bold text-hover-primary d-block mb-1 fs-6"
                  >
                    {permit?.description}
                  </a>
                </td>
                <td>
                  <a
                    href="#"
                    className="text-muted fw-bold text-hover-primary d-block mb-1 fs-6"
                  >
                    {permit?.permittype_id}
                  </a>
                </td>
                <td>
                  <a
                    href="#"
                    className="text-muted fw-bold text-hover-primary d-block mb-1 fs-6"
                  >
                    {convertDate(permit?.created_at)}
                  </a>
                </td>
                {/* <td>
                  <a
                    href="#"
                    className="text-muted fw-bold text-hover-primary d-block mb-1 fs-6"
                  >
                    {dropPoint?.pilot_id}
                  </a>
                </td> */}
                <td className="text-end">
                  <a
                    href="#"
                    className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                    onClick={() => onEdit(permit)}
                  >
                    <KTSVG
                      path="/media/icons/duotune/art/art005.svg"
                      className="svg-icon-3"
                    />
                  </a>
                  {(role == "Administrator" || role == "Pilot") && (
                    <a
                      href="#"
                      className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                      onClick={() => onTriggerDelete(permit.id)}
                    >
                      <KTSVG
                        path="/media/icons/duotune/general/gen027.svg"
                        className="svg-icon-3"
                      />
                    </a>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
          {/* end::Table body */}
        </table>
        {/* end::Table */}
      </div>
      <ConfirmationModal
        show={showModal}
        handleClose={() => setShowModal(false)}
      >
        <div className="modal-header">
          <h2>Delete Permit</h2>
        </div>

        <div className="modal-body py-lg-10 px-lg-10">
          <h3>Are you sure want to proceed?</h3>
        </div>
        <div className="modal-footer d-flex justify-content-between py-6 px-9">
          <button
            type="button"
            className="btn btn-default"
            onClick={() => setShowModal(false)}
          >
            <span className="indicator-progress" style={{ display: "block" }}>
              Close
              {/* Please wait...{" "} */}
              {/* <span className="spinner-border spinner-border-sm align-middle ms-2"></span> */}
            </span>
          </button>
          <button
            type="submit"
            className="btn btn-danger"
            onClick={confirmDelete}
          >
            <span className="indicator-progress" style={{ display: "block" }}>
              Confirm
            </span>
          </button>
        </div>
      </ConfirmationModal>
    </>
  );
};
