import { useFormik } from "formik";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import * as Yup from "yup";
import { EJKNServiceSingleton } from "../../../../services/api/ejkns.service";
import { ToastrServiceSingleton } from "../../../../services/toastr/toastr.service";
import { IState } from "../StateModel";

const statesDetailsSchema = Yup.object().shape({
  name: Yup.string().required("Zone name is required"),
  abbreviation: Yup.string().required("Abbreviation is required"),
});

const ZoneForm: any = (props: any) => {
  const [loading, setLoading] = useState(false);

  const formik = useFormik<IState>({
    initialValues: props.initialValues,
    validationSchema: statesDetailsSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        if (values?.id && values.id) {
          await props.onFormSubmit(values.id, values);
          ToastrServiceSingleton.success("Successfull update zone");
        } else {
          await props.onFormSubmit(values);
          formik.resetForm();
          ToastrServiceSingleton.success("Successfull create zone");
        }
      } catch (error) {
        console.log(error, "error");
        ToastrServiceSingleton.error("Failed to create zone!");
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <div className="card mb-5 mb-xl-10">
      <div
        className="card-header border-0 cursor-pointer"
        role="button"
        data-bs-toggle="collapse"
        data-bs-target="#kt_account_profile_details"
        aria-expanded="true"
        aria-controls="kt_account_profile_details"
      >
        <div className="card-title m-0">
          <h3 className="fw-bolder m-0">
            {props.initialValues?.id ? "Edit" : "Add"} State
          </h3>
        </div>
      </div>
      {props.mainLoading ? (
        <Skeleton width={"100%"} height={30} />
      ) : (
        <form onSubmit={formik.handleSubmit} noValidate className="form">
          <div className="card-body border-top p-9">
            <div className="row mb-6">
              <label className="col-lg-4 col-form-label required fw-bold fs-6">
                STate Name
              </label>

              <div className="col-lg-8">
                <div className="row">
                  <div className="col-lg-12 fv-row">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                      placeholder="State name"
                      {...formik.getFieldProps("name")}
                    />
                    {formik.touched.name && formik.errors.name && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.name}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-6">
              <label className="col-lg-4 col-form-label required fw-bold fs-6">
                Abbreviation
              </label>

              <div className="col-lg-8 fv-row">
                <textarea
                  className="form-control form-control-solid mb-8"
                  rows={3}
                  placeholder="Abbreviation"
                  {...formik.getFieldProps("abbreviation")}
                ></textarea>
                {formik.touched.abbreviation && formik.errors.abbreviation && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.abbreviation}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="card-footer d-flex justify-content-end py-6 px-9">
            <button type="submit" className="btn btn-primary">
              <span className="indicator-progress" style={{ display: "block" }}>
                {!loading && "Submit"}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: "block" }}
                  >
                    Please wait...{" "}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </span>
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default ZoneForm;
