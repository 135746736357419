export interface IState {
  name: string;
  abbreviation: string;
  id?: number;
}

export const stateInitialValues: IState = {
  name: "",
  abbreviation: "",
};
