import { useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { EJKNServiceSingleton } from "../../../services/api/ejkns.service";
import { ToastrServiceSingleton } from "../../../services/toastr/toastr.service";
import StatusTable from "./components/StatusTable";

export default function StatusLists() {
  const [loading, setLoading] = useState(false);
  const [statuses, setStatuses] = useState([]);

  function handleDeletionLocally(id: number) {
    setStatuses((status) => status.filter((r: any) => r.id !== id));
  }

  useEffect(() => {
    const getZones = async () => {
      try {
        setLoading(true);
        const lists = await EJKNServiceSingleton.statuses();
        if (lists.data.length > 0) setStatuses(lists.data);
      } catch (error) {
        ToastrServiceSingleton.error("Fail to fetch status lists");
      } finally {
        setLoading(false);
      }
    };
    getZones();
  }, []);

  return (
    <div className="card mb-5 mb-xl-10">
      <div
        className="card-header border-0 cursor-pointer"
        role="button"
        data-bs-toggle="collapse"
        data-bs-target="#kt_account_profile_details"
        aria-expanded="true"
        aria-controls="kt_account_profile_details"
      >
        <div className="card-title m-0">
          <h3 className="fw-bolder m-0">List of Statuses</h3>
        </div>
      </div>
      <div className="card-body border-top p-9">
        {loading ? (
          <>
            <SkeletonTheme>
              {/* <div className="row col-md-12"> */}
              <Skeleton width={"100%"} height={30} />
              <Skeleton count={5} width={"100%"} height={30} />
              {/* </div> */}
            </SkeletonTheme>
          </>
        ) : (
          <StatusTable
            statusLists={statuses}
            handleDeletionLocally={handleDeletionLocally}
          />
        )}
      </div>
    </div>
  );
}
