import React from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import AddPermitTypePage from "./AddPermitType";
import PermitTypeLists from "./PermitTypeLists";
// import AddStatusPage from "./AddStatusPage";
// import StatusLists from "./StatusLists";

// import AddDistrictPage from "./AddDistrictPage";
// import DistrictLists from "./DistrictLists";

const permitTypeBreadCrumb: Array<PageLink> = [
  {
    title: "Edit Permit Type",
    path: "/permittype/edit",
    isSeparator: false,
    isActive: false,
  },
  {
    title: "Add Permit Type",
    path: "/permittype/add",
    isSeparator: false,
    isActive: false,
  },
  {
    title: "List of Permit Type",
    path: "/permittype/lists",
    isSeparator: true,
    isActive: false,
  },
];

const PermitTypePage: React.FC = () => {
  return (
    <Routes>
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      >
        <Route
          path="edit/:id"
          element={
            <>
              <PageTitle breadcrumbs={permitTypeBreadCrumb}>
                Edit Permit Type
              </PageTitle>
              <AddPermitTypePage />
            </>
          }
        />
        <Route
          path="add"
          element={
            <>
              <PageTitle breadcrumbs={permitTypeBreadCrumb}>
                Add Permit Type
              </PageTitle>
              <AddPermitTypePage />
            </>
          }
        />
        <Route
          path="lists"
          element={
            <>
              <PageTitle breadcrumbs={permitTypeBreadCrumb}>
                List of Permit Types
              </PageTitle>
              <PermitTypeLists />
            </>
          }
        />
      </Route>
    </Routes>
  );
};

export default PermitTypePage;
