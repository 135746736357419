import React from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import AddDistrictPage from "./AddDistrictPage";
import DistrictLists from "./DistrictLists";

const districtBreadCrumb: Array<PageLink> = [
  {
    title: "Add District",
    path: "/district/add",
    isSeparator: false,
    isActive: false,
  },
  {
    title: "List of Districts",
    path: "/district/lists",
    isSeparator: true,
    isActive: false,
  },
];

const DistrictsPage: React.FC = () => {
  return (
    <Routes>
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      >
        <Route
          path="edit/:id"
          element={
            <>
              <PageTitle breadcrumbs={districtBreadCrumb}>
                Edit District
              </PageTitle>
              <AddDistrictPage action="Edit" />
            </>
          }
        />
        <Route
          path="add"
          element={
            <>
              <PageTitle breadcrumbs={districtBreadCrumb}>
                Add District
              </PageTitle>
              <AddDistrictPage />
            </>
          }
        />
        <Route
          path="lists"
          element={
            <>
              <PageTitle breadcrumbs={districtBreadCrumb}>
                List of District
              </PageTitle>
              <DistrictLists />
            </>
          }
        />
      </Route>
    </Routes>
  );
};

export default DistrictsPage;
