import React, { useEffect, useState } from "react";
import { EJKNServiceSingleton } from "../../../../services/api/ejkns.service";
import { ToastrServiceSingleton } from "../../../../services/toastr/toastr.service";
import { DateUtilsServiceSingleton } from "../../../../services/utilities/data/date-utils.service";
import { KTSVG } from "../../../../_metronic/helpers";
import { ConfirmationModal } from "../../widgets/components/ConfirmationModal";

export const SignatureTable = ({
  signatures = [] as any[],
  //   onEdit = (data: any) => {},
  //   role = "",
}) => {
  const [showModal, setShowModal] = useState(false);

  function convertDate(date: string) {
    return DateUtilsServiceSingleton.convertDateDefault(date);
  }

  return (
    <>
      <div className="table-responsive">
        {/* begin::Table */}
        <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
          {/* begin::Table head */}
          <thead>
            <tr className="fw-bold text-muted">
              <th className="text-dark">No</th>
              <th className="text-dark">Name</th>
              <th className="text-dark">Designation</th>
              <th className="text-dark">Location</th>
              <th className="text-dark">Date</th>
              {/* <th className="text-dark">Pilot</th> */}
              {/* <th className="text-dark text-end">Action</th> */}
            </tr>
          </thead>
          {/* end::Table head */}
          {/* begin::Table body */}
          <tbody>
            {signatures.map((sign: any, i: number) => (
              <tr key={sign.id}>
                <td>
                  <span className="text-dark fw-bold text-hover-primary fs-6">
                    {i + 1}
                  </span>
                </td>
                <td>
                  <a
                    href="#"
                    className="text-muted fw-bold text-hover-primary d-block mb-1 fs-6"
                  >
                    {sign?.fullname}
                  </a>
                </td>
                <td>
                  <a
                    href="#"
                    className="text-muted fw-bold text-hover-primary d-block mb-1 fs-6"
                  >
                    {sign?.designation}
                  </a>
                </td>
                <td>
                  <a
                    href="#"
                    className="text-muted fw-bold text-hover-primary d-block mb-1 fs-6"
                  >
                    {sign?.location}
                  </a>
                </td>
                <td>
                  <a
                    href="#"
                    className="text-muted fw-bold text-hover-primary d-block mb-1 fs-6"
                  >
                    {convertDate(sign?.created_at)}
                  </a>
                </td>
                {/* <td>
                  <a
                    href="#"
                    className="text-muted fw-bold text-hover-primary d-block mb-1 fs-6"
                  >
                    {dropPoint?.pilot_id}
                  </a>
                </td> */}
                {/* <td className="text-end">
                  <a
                    href="#"
                    className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                    // onClick={() => onEdit(dropPoint)}
                  >
                    <KTSVG
                      path="/media/icons/duotune/art/art005.svg"
                      className="svg-icon-3"
                    />
                  </a>
                </td> */}
              </tr>
            ))}
          </tbody>
          {/* end::Table body */}
        </table>
        {/* end::Table */}
      </div>
    </>
  );
};
