import React from "react";
import { useSelector } from "react-redux";
import { Outlet, Route, Routes } from "react-router-dom";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import { AddNewTicketPage } from "./AddNewTicketPage";
import { CompletedTicketPage } from "./CompleteTicketPage";
import { CreateNewTicketPage } from "./CreateNewTicketPage";
import { InProgressTicketPage } from "./InProgressTicketPage";
import { OpenTicketPage } from "./OpenTicketPage";
// import AddOrganizationPage from "./AddOrganizationPage";
// import OrganizationLists from "./OrganizationLists";

const ticketBreadCrumb: Array<PageLink> = [
  {
    title: "Open Ticket",
    path: "/ticket/open",
    isSeparator: false,
    isActive: false,
  },
  {
    title: "Add New Ticket",
    path: "/ticket/open/new",
    isSeparator: false,
    isActive: false,
  },
  // {
  //   title: "Ticket Details",
  //   path: "/ticket/detail/:id",
  //   isSeparator: false,
  //   isActive: false,
  // },
  {
    title: "In-Progress Ticket",
    path: "/ticket/inprogress",
    isSeparator: true,
    isActive: false,
  },
  {
    title: "Completed Ticket",
    path: "/ticket/completed",
    isSeparator: true,
    isActive: false,
  },
];

const ticketBreadCrumbReadOnly: Array<PageLink> = [
  {
    title: "Open Ticket",
    path: "/ticket/open",
    isSeparator: false,
    isActive: false,
  },
  {
    title: "In-Progress Ticket",
    path: "/ticket/inprogress",
    isSeparator: true,
    isActive: false,
  },
  {
    title: "Completed Ticket",
    path: "/ticket/completed",
    isSeparator: true,
    isActive: false,
  },
];

const TicketPage: React.FC = () => {
  const { myRole } = useSelector(({ roles }) => roles);

  return (
    <Routes>
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      >
        <Route
          path="open"
          element={
            <>
              <PageTitle
                breadcrumbs={
                  myRole === "Administrator" || myRole === "Pilot"
                    ? ticketBreadCrumb
                    : ticketBreadCrumbReadOnly
                }
              >
                Open Ticket
              </PageTitle>
              <div>
                <OpenTicketPage />
              </div>
            </>
          }
        />
        <Route
          path="open/new"
          element={
            <>
              <PageTitle
                breadcrumbs={
                  myRole === "Administrator" || myRole === "Pilot"
                    ? ticketBreadCrumb
                    : ticketBreadCrumbReadOnly
                }
              >
                Add New Ticket
              </PageTitle>
              <div>
                <AddNewTicketPage />
              </div>
            </>
          }
        />

        <Route
          path="inprogress"
          element={
            <>
              <PageTitle
                breadcrumbs={
                  myRole === "Administrator" || myRole === "Pilot"
                    ? ticketBreadCrumb
                    : ticketBreadCrumbReadOnly
                }
              >
                In Progress Ticket
              </PageTitle>
              <InProgressTicketPage />
            </>
          }
        />
        <Route
          path="completed"
          element={
            <>
              <PageTitle
                breadcrumbs={
                  myRole === "Administrator" || myRole === "Pilot"
                    ? ticketBreadCrumb
                    : ticketBreadCrumbReadOnly
                }
              >
                Completed Ticket
              </PageTitle>
              <CompletedTicketPage />
            </>
          }
        />
        <Route
          path="detail/:id"
          element={
            <>
              <PageTitle
                breadcrumbs={
                  myRole === "Administrator" || myRole === "Pilot"
                    ? ticketBreadCrumb
                    : ticketBreadCrumbReadOnly
                }
              >
                Ticket Details
              </PageTitle>
              <CreateNewTicketPage />
            </>
          }
        />
      </Route>
    </Routes>
  );
};

export default TicketPage;
