export interface IDistrict {
  description: string;
  name: string;
  state_id: string;
  id?: string;
}

export const districtInitialValues: IDistrict = {
  name: "",
  description: "",
  state_id: "",
  id: "",
};
