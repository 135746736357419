import { createAsyncThunk } from "@reduxjs/toolkit";
import { EJKNServiceSingleton } from "../../services/api/ejkns.service";
import { store } from "../../store";
import { login, success, error, userSuccess, reset } from "../global/global";
import { persistStore } from "redux-persist";

let persistor = persistStore(store);
export const initiateLogin = createAsyncThunk(
  "thunk/initiateLogin",
  async (data: any, thunkAPI) => {
    try {
      thunkAPI.dispatch(reset());
      thunkAPI.dispatch(login());
      const resp = await EJKNServiceSingleton.authenticate(data);
      if (resp) {
        thunkAPI.dispatch(success(resp));
      }
      const userResp = await EJKNServiceSingleton.me();
      if (userResp) {
        thunkAPI.dispatch(userSuccess(userResp.user));
      }
    } catch (erMsg) {
      thunkAPI.dispatch(error(erMsg.message));
      return thunkAPI.rejectWithValue("something went wrong");
    }
  }
);

export const initiateLogout = createAsyncThunk(
  "thunk/initiateLogout",
  async (data: any, thunkAPI) => {
    try {
      thunkAPI.dispatch(reset());
      // localStorage.removeItem("persist:reducer");

      await persistor.purge();
      await persistor.flush();
      thunkAPI.dispatch(reset);
    } catch (erMsg) {
      thunkAPI.dispatch(error(erMsg));
      return thunkAPI.rejectWithValue("something went wrong");
    }
  }
);
