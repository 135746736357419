import React from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import AddZonePage from "./AddZonePage";
import ZoneLists from "./ZoneLists";
// import AddDistrictPage from "./AddDistrictPage";
// import DistrictLists from "./DistrictLists";

const zoneBreadCrumb: Array<PageLink> = [
  {
    title: "Edit Zone",
    path: "/zone/edit",
    isSeparator: false,
    isActive: false,
  },
  {
    title: "Add Zone",
    path: "/zone/add",
    isSeparator: false,
    isActive: false,
  },
  {
    title: "List of Zones",
    path: "/zone/lists",
    isSeparator: true,
    isActive: false,
  },
];

const ZonePage: React.FC = () => {
  return (
    <Routes>
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      >
        <Route
          path="edit/:id"
          element={
            <>
              <PageTitle breadcrumbs={zoneBreadCrumb}>Edit Zone</PageTitle>
              <AddZonePage />
            </>
          }
        />
        <Route
          path="add"
          element={
            <>
              <PageTitle breadcrumbs={zoneBreadCrumb}>Add Zone</PageTitle>
              <AddZonePage />
            </>
          }
        />
        <Route
          path="lists"
          element={
            <>
              <PageTitle breadcrumbs={zoneBreadCrumb}>List of Zones</PageTitle>
              <ZoneLists />
            </>
          }
        />
      </Route>
    </Routes>
  );
};

export default ZonePage;
