import * as dayjs from "dayjs";

class DateUtilsService {
  private static instance: DateUtilsService;
  private _dayjs: any;
  private _defaultFormat = "DD-MM-YYYY";

  private constructor() {}

  public static getInstance(): DateUtilsService {
    if (!DateUtilsService.instance) {
      DateUtilsService.instance = new DateUtilsService();
      DateUtilsService.instance._settingDayJs();
    }

    return DateUtilsService.instance;
  }

  private _settingDayJs() {
    this._dayjs = dayjs;
  }

  convertDateDefault(date: string, format: string = null) {
    return this._dayjs(date).format(format || this._defaultFormat);
  }
}

export const DateUtilsServiceSingleton = DateUtilsService.getInstance();
